import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';
import { RentalIncomeResponse } from '../utils/interfaces';
import { useRentalIncomeStore } from '../stores/RentalIncomeStore';

export const useFetchRentalIncome = () => {
  const { rentalIncome, setRentalIncome } = useRentalIncomeStore();

  const { data, isLoading, isError } = useQuery<RentalIncomeResponse>({
    queryKey: ['rentalincome'],
    queryFn: async () => {
      if (rentalIncome) {
        return rentalIncome;
      }

      const data = await api.getRentalIncome();
      setRentalIncome(data);
      return data;
    },
    enabled: !rentalIncome, // Only fetch from API if not available in store
    refetchOnWindowFocus: true,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 30, // 30 minutes
  });

  return {
    rentalIncome: data,
    isLoading,
    isError,
  };
};
