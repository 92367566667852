import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { api } from '../utils/api';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { AccessRequest, Property } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../helper/LoadingSpinner';

const Request = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const rowsPerPage = 15;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await api.getProperties();
        setProperties(response);
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const handleRequestAccess = (property: Property) => {
    if (property.UserID && property.PropertyID) {
      const requestData: AccessRequest = {
        LandlordID: property.UserID,
        PropertyID: property.PropertyID,
        Status: 'Pending',
      };

      api
        .createAccessRequest(requestData)
        .then((response) => {
          toast.success(t(response.message));
        })
        .catch((error) => {
          ErrorHandler.handleError(error, errorMessages, toast);
        });
    }
  };

  const handleViewStatus = (property: Property) => {
    if (property.PropertyID) {
      api
        .getAccessRequestsByID(property.PropertyID)
        .then((status) => {
          if (status) {
            setStatus(status);
            setOpenModal(true);
          } else {
            toast.info('No access requests found for this property.');
          }
        })
        .catch(() => {
          toast.error('Error fetching status');
        });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const filteredProperties = properties.filter((property) =>
    property.PropertyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProperties = filteredProperties.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('propertyRequests')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('availableProperties')}
      </Typography>

      <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
        <TextField
          label={t('searchPropertyName')}
          variant="outlined"
          sx={{ width: '50%' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: searchTerm ? 'visible' : 'hidden' }}
                onClick={handleClearSearch}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      <TableContainer
        component={Paper}
        sx={{ mt: 2, maxHeight: '500px', overflow: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Property Name</strong>
              </TableCell>
              <TableCell>
                <strong>Location</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProperties.length > 0 ? (
              paginatedProperties.map((property, index) => (
                <TableRow
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <TableCell>{property.PropertyName}</TableCell>
                  <TableCell>{property.Location}</TableCell>
                  <TableCell>
                    <Tooltip title={t('requestAccess')} arrow>
                      <IconButton
                        color="primary"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FF9B5D',
                          },
                          '& svg': {
                            color: 'black',
                          },
                          '&:hover svg': {
                            color: 'white',
                          },
                        }}
                        onClick={() => handleRequestAccess(property)}
                      >
                        <VpnKeyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('viewStatus')} arrow>
                      <IconButton
                        color="primary"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FF9B5D',
                          },
                          '& svg': {
                            color: 'black',
                          },
                          '&:hover svg': {
                            color: 'white',
                          },
                        }}
                        onClick={() => handleViewStatus(property)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredProperties.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Modal to display the status */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 3,
            borderRadius: 2,
            maxWidth: 400,
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('requestStatus')}
          </Typography>
          <Typography variant="body1">
            {status ? status : t('loading')}
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Request;
