import React, { useState, useEffect } from 'react';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import TrashIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { useFetchProperties } from '../hooks/property.hooks';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import { Room, Property as PropertyProps } from '../utils/interfaces';
import PropertyModal from './PropertyModal';
import { api } from '../utils/api';
import { deleteKeyStore } from '../utils/indexedDB';
import { useFetchRooms } from '../hooks/room.hooks';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { UserRoleType } from '../types/types';
import { useUserStore } from '../stores/UserStore';
import LoadingSpinner from '../helper/LoadingSpinner';
import { useAuth } from '../helper/AuthContext';

const Property = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const {
    data: properties,
    isError: isPropertyError,
    error: propertyError,
    isLoading: isPropertyLoading,
    refetch: refetchProperties,
  } = useFetchProperties();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProperties, setFilteredProperties] = useState(
    properties || []
  );
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const errorMessages = new ErrorMessages();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [selectedType, setSelectedType] = useState('');
  const [propertyDetails, setPropertyDetails] = useState<PropertyProps>({
    PropertyID: '',
    UserID: '',
    PropertyType: '',
    PropertyName: '',
    Price: 0,
    NumberOfUnits: 0,
    NumberOfBathrooms: 0,
    NumberOfBedrooms: 0,
    Location: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const { userRoleType } = useAuth();
  const isCaretaker = userRoleType === UserRoleType.Caretaker;

  const { refetch: refetchRooms } = useFetchRooms(
    propertyDetails.PropertyID as string
  );

  useEffect(() => {
    if (isPropertyLoading) {
      <LoadingSpinner />;
      return;
    }
  }, [isPropertyLoading]);

  useEffect(() => {
    if (isPropertyError) {
      ErrorHandler.handleError(propertyError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPropertyError, propertyError, errorMessages, toast]);

  useEffect(() => {
    if (properties) {
      setFilteredProperties(
        properties.filter((property) =>
          property.PropertyName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, properties]);

  const handleRefetch = async () => {
    await refetchProperties();
    await refetchRooms();
  };

  const handleSearchChange = (event: SelectChangeEvent<string>) => {
    setSearchTerm(event.target.value);
  };

  const handleResetFilter = () => {
    setSearchTerm('');
    setFilteredProperties(properties || []);
  };

  const handleSaveProperty = async () => {
    const validationErrors: { [key: string]: string } = {};
    if (!propertyDetails.PropertyName) {
      validationErrors.PropertyName = 'Property Name is required';
    }
    if (!propertyDetails.Price) {
      validationErrors.Price = t('price_required');
    }
    if (!propertyDetails.Location) {
      validationErrors.Location = t('location_required');
    }
    if (!propertyDetails.NumberOfUnits) {
      validationErrors.NumberOfUnits = t('units_required');
    }
    if (!selectedType) {
      validationErrors.PropertyType = t('property_type_required');
    }
    if (selectedType !== 'Hostel' && !isEditMode) {
      if (!propertyDetails.NumberOfBedrooms) {
        validationErrors.NumberOfBedrooms = t('bedrooms_required');
      }
      if (!propertyDetails.NumberOfBathrooms) {
        validationErrors.NumberOfBathrooms = t('bathrooms_required');
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }

    try {
      let response;
      if (propertyDetails.PropertyID) {
        // Find the original property using the hook data
        const originalProperty = properties?.find(
          (prop) => prop.PropertyID === propertyDetails.PropertyID
        );

        if (!originalProperty) {
          setOpenPropertyModal(false);
          return;
        }

        // Extract and compare fields
        const originalUnits = originalProperty.NumberOfUnits || 0;
        const newUnits = propertyDetails.NumberOfUnits || 0;

        const hasNameChanged =
          propertyDetails.PropertyName !== originalProperty.PropertyName;
        const hasPriceChanged =
          propertyDetails.Price !== originalProperty.Price;

        const hasUnitsChanged = Number(newUnits) !== Number(originalUnits);

        // If no changes, return early (skipping finally block)
        if (!hasNameChanged && !hasPriceChanged && !hasUnitsChanged) {
          toast.info('No changes detected, skipping update.');
          setOpenPropertyModal(false);
          return;
        }

        // Update name or price if changed
        if (hasNameChanged || hasPriceChanged) {
          response = await api.updateProperty(
            propertyDetails.PropertyID,
            propertyDetails
          );
          toast.success(t('update_property_success'));
        }

        // Handle units change (add rooms if newUnits > originalUnits)
        if (Number(newUnits) > Number(originalUnits)) {
          const unitsToAdd = newUnits - originalUnits; // Calculate difference between new and original units

          for (let i = 0; i < unitsToAdd; i++) {
            const roomData: Room = {
              PropertyID: propertyDetails.PropertyID,
              PropertyName: propertyDetails.PropertyName,
              Price: propertyDetails.Price,
            };

            try {
              await api.createRoom(roomData);
            } catch (error: any) {
              ErrorHandler.handleError(error, errorMessages, toast);
              break;
            }
          }
        }
      } else {
        // **CREATE NEW PROPERTY**
        response = await api.createProperty(propertyDetails);
        if (response.PropertyID) {
          for (let i = 0; i < (propertyDetails.NumberOfUnits || 1); i++) {
            const roomData: Room = {
              PropertyID: response.PropertyID,
              PropertyName: propertyDetails.PropertyName,
              Price: propertyDetails.Price,
            };

            try {
              await api.createRoom(roomData);
            } catch (error: any) {
              ErrorHandler.handleError(error, errorMessages, toast);
              break;
            }
          }
          toast.success(`${propertyDetails.PropertyName} created successfully`);
        }
      }
    } catch (error: any) {
      ErrorHandler.handleError(error, errorMessages, toast);
    } finally {
      // Only reset UI & refetch if a new property was created or updated
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${propertyDetails.PropertyID}`);
      setOpenPropertyModal(false);
      handleRefetch();

      resetForm();
    }
  };

  const handleDeleteProperty = async (propertyId: string) => {
    try {
      await api.deleteProperty(propertyId);
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${propertyDetails.PropertyID}`);
      handleRefetch();

      toast.success(t('delete_success'));
      setOpenDeleteModal(false);
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const handleCreateProperty = () => {
    setIsEditMode(true);
    setOpenPropertyModal(true);
    setSelectedType('');
    setPropertyDetails({
      PropertyID: '',
      UserID: user?.UserID || '',
      PropertyType: '',
      PropertyName: '',
      Price: 0,
      NumberOfUnits: 0,
      NumberOfBathrooms: 0,
      NumberOfBedrooms: 0,
      Location: '',
    });
  };

  const handleEditProperty = (property: any) => {
    setIsEditMode(true);
    setSelectedType(property.PropertyType);
    setPropertyDetails({
      PropertyID: property.PropertyID,
      UserID: property.UserID,
      PropertyType: property.PropertyType,
      PropertyName: property.PropertyName,
      Price: property.Price,
      Location: property.Location,
      NumberOfUnits: property.NumberOfUnits,
      NumberOfBedrooms: property.NumberOfBedrooms,
      NumberOfBathrooms: property.NumberOfBathrooms,
    });

    setOpenPropertyModal(true);
  };

  const handleViewProperty = (property: PropertyProps) => {
    setIsEditMode(false);
    setSelectedType(property.PropertyType);
    setPropertyDetails({
      PropertyID: property.PropertyID,
      UserID: property.UserID,
      PropertyType: property.PropertyType,
      PropertyName: property.PropertyName,
      Price: property.Price,
      Location: property.Location,
      NumberOfUnits: property.NumberOfUnits,
      NumberOfBedrooms: property.NumberOfBedrooms,
      NumberOfBathrooms: property.NumberOfBathrooms,
    });

    setOpenPropertyModal(true);
  };

  const handleClosePropertyModal = () => {
    setOpenPropertyModal(false);
    resetForm();
  };

  const resetForm = () => {
    setPropertyDetails({
      PropertyID: '',
      UserID: '',
      PropertyType: '',
      PropertyName: '',
      Price: 0,
      NumberOfUnits: 0,
      NumberOfBathrooms: 0,
      NumberOfBedrooms: 0,
      Location: '',
    });
    setValidationErrors({});
    setSelectedType('');
  };

  const handleConfirmDelete = () => {
    if (selectedProperty?.PropertyID) {
      handleDeleteProperty(selectedProperty.PropertyID);
    }
    setOpenDeleteModal(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isPropertyLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('properties_title')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('properties_description', { propertyCount: properties?.length })}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <FormControl
          variant="outlined"
          sx={{ width: 200, mr: 2, height: '40px' }}
        >
          <InputLabel sx={{ height: '40px' }}>Property Name</InputLabel>
          <Select
            value={searchTerm}
            onChange={handleSearchChange}
            label="Property Name"
            sx={{ height: '40px' }}
          >
            {properties?.map((property) => (
              <MenuItem key={property.PropertyID} value={property.PropertyName}>
                {property.PropertyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleResetFilter}
          sx={{ height: '40px', padding: '0 16px' }}
        >
          {t('reset_button')}
        </Button>
        {!isCaretaker && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: 2,
              height: '40px',
              padding: '0 16px',
              backgroundColor: '#EC675D',
              '&:hover': {
                backgroundColor: '#FF9B5D',
              },
            }}
            onClick={handleCreateProperty}
          >
            {t('new_property_button')}
          </Button>
        )}
      </Box>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{ mt: 4, maxHeight: '400px', overflow: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('type')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('name')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('units')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('price')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('address')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('actions')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProperties?.length ? (
              filteredProperties
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    <TableCell>{row.PropertyType}</TableCell>
                    <TableCell>{row.PropertyName}</TableCell>
                    <TableCell>{row.NumberOfUnits}</TableCell>
                    <TableCell>{row.Price}</TableCell>
                    <TableCell>{row.Location}</TableCell>
                    <TableCell>
                      {/* View Icon */}
                      <Tooltip title={t('view_button')} arrow>
                        <IconButton
                          color="primary"
                          sx={{
                            '&:hover': {
                              backgroundColor: '#FF9B5D',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: 'white',
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewProperty(row);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {/* Edit and Delete Icons */}
                      {!isCaretaker && (
                        <>
                          {/* Edit Icon */}
                          <Tooltip title={t('edit_button')} arrow>
                            <IconButton
                              color="primary"
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#FF9B5D',
                                },
                                '& svg': {
                                  color: 'black',
                                },
                                '&:hover svg': {
                                  color: 'white',
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditProperty(row);
                              }}
                              disabled={isCaretaker}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          {/* Delete Icon */}
                          <Tooltip title={t('delete_button')} arrow>
                            <IconButton
                              color="error"
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#fff',
                                },
                                '& svg': {
                                  color: 'black',
                                },
                                '&:hover svg': {
                                  color: 'red',
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedProperty(row);
                                setOpenDeleteModal(true);
                              }}
                              disabled={isCaretaker}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={filteredProperties.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Delete Confirmation Modal */}
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>{t('delete_property_title')}</DialogTitle>
        <DialogContent>
          {t('delete_property_message', {
            propertyName: selectedProperty?.PropertyName,
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Property Modal for Add, Edit, View */}
      <PropertyModal
        open={openPropertyModal}
        onClose={handleClosePropertyModal}
        onSave={handleSaveProperty}
        propertyDetails={propertyDetails}
        validationErrors={validationErrors}
        setPropertyDetails={setPropertyDetails}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        isEditMode={isEditMode}
      />
    </Box>
  );
};

export default Property;
