import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../stores/UserStore';
import { useRentalIncomeStore } from '../stores/RentalIncomeStore';
import LoadingSpinner from '../helper/LoadingSpinner';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getLastSixMonths = () => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const lastSixMonths: { month: string; year: number }[] = [];

  for (let i = 5; i >= 0; i--) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() - i);

    lastSixMonths.push({
      month: months[date.getMonth()],
      year: date.getFullYear(),
    });
  }

  return lastSixMonths;
};

const calculateMonthlyRentalIncome = (rentalIncome: any) => {
  if (!rentalIncome || !rentalIncome.Tenants) return [];

  const lastSixMonths = getLastSixMonths();
  const monthlyRentals: { [key: string]: number } = {};

  lastSixMonths.forEach(({ month, year }) => {
    monthlyRentals[`${month} ${year}`] = 0;
  });

  rentalIncome.Tenants.forEach((tenant: any) => {
    const { RoomPrice, StartDate, EndDate } = tenant;
    const startDate = new Date(StartDate);
    const endDate = new Date(EndDate);
    const monthlyRent = RoomPrice / 12; // Convert yearly rent to monthly rent

    lastSixMonths.forEach(({ month, year }) => {
      const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
      const monthStartDate = new Date(year, monthIndex, 1);
      const monthEndDate = new Date(year, monthIndex + 1, 0);

      if (startDate <= monthEndDate && endDate >= monthStartDate) {
        // Find the actual occupied start and end dates within this month
        const actualStartDate =
          startDate > monthStartDate ? startDate : monthStartDate;
        const actualEndDate = endDate < monthEndDate ? endDate : monthEndDate;

        // Calculate number of days occupied in this month
        const daysInMonth = monthEndDate.getDate();
        const daysOccupied =
          (actualEndDate.getTime() - actualStartDate.getTime()) /
            (1000 * 60 * 60 * 24) +
          1; // +1 to include both start and end date

        const proratedRent = Math.round(
          (monthlyRent * daysOccupied) / daysInMonth
        ); // Round to nearest whole number

        monthlyRentals[`${month} ${year}`] += proratedRent;
      }
    });
  });

  return Object.values(monthlyRentals).map((value) => Math.round(value)); // Ensure all values are rounded
};

const MonthlyChart = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const { rentalIncome, isLoading: isRentalIncomeLoading } =
    useRentalIncomeStore();

  const lastSixMonths = getLastSixMonths().map(
    ({ month, year }) => `${month} ${year}`
  );
  const monthlyRevenue = rentalIncome
    ? calculateMonthlyRentalIncome(rentalIncome)
    : [];

  const data = {
    labels: lastSixMonths,
    datasets: [
      {
        label: 'Monthly Rental Income',
        data: monthlyRevenue,
        backgroundColor: '#F09950',
        borderColor: '#1976d2',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: `Revenue in ${user?.Currency || 'USD'}`,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box sx={{ mt: 8 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" fontWeight="bold" color="black">
          {t('monthly_rental_income_trends')}
        </Typography>

        <Typography variant="body1" sx={{ mt: 2, mb: 4 }} color="textSecondary">
          {t('rental_income_visualization')}
        </Typography>

        {isRentalIncomeLoading ? (
          <LoadingSpinner />
        ) : (
          <Bar data={data} options={options} />
        )}
      </Paper>
    </Box>
  );
};

export default MonthlyChart;
