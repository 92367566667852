import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../stores/UserStore';
import { useRentalIncomeStore } from '../stores/RentalIncomeStore';
import LoadingSpinner from '../helper/LoadingSpinner';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getLastSixYears = () => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];

  for (let i = 0; i < 6; i++) {
    years.push(currentYear - i);
  }

  return years.reverse();
};

const calculateYearlyRentalIncome = (rentalIncome: any) => {
  if (!rentalIncome || !rentalIncome.Tenants) return [];

  const lastSixYears = getLastSixYears();
  const yearlyRentals: { [key: number]: number } = {};

  // Initialize yearly revenue
  lastSixYears.forEach((year) => {
    yearlyRentals[year] = 0;
  });

  rentalIncome.Tenants.forEach((tenant: any) => {
    const { RoomPrice, StartDate, EndDate } = tenant;
    const startDate = new Date(StartDate);
    const endDate = new Date(EndDate);

    lastSixYears.forEach((year) => {
      const yearStartDate = new Date(year, 0, 1); // Jan 1st of year
      const yearEndDate = new Date(year, 11, 31); // Dec 31st of year

      // Check if tenant was active in this year
      if (startDate <= yearEndDate && endDate >= yearStartDate) {
        // Determine months the tenant was active in this year
        const activeStart =
          startDate < yearStartDate ? yearStartDate : startDate;
        const activeEnd = endDate > yearEndDate ? yearEndDate : endDate;

        const monthsOccupied =
          (activeEnd.getFullYear() - activeStart.getFullYear()) * 12 +
          (activeEnd.getMonth() - activeStart.getMonth()) +
          1; // +1 to count the current month

        // Full-year rent is always RoomPrice; distribute it over 12 months
        const monthlyRent = RoomPrice / 12;
        yearlyRentals[year] += monthlyRent * monthsOccupied;
      }
    });
  });

  return Object.values(yearlyRentals).map((value) => Math.round(value));
};

const YearlyChart = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { rentalIncome, isLoading: isRentalIncomeLoading } =
    useRentalIncomeStore();

  const lastSixYears = getLastSixYears();
  const yearlyRevenue = rentalIncome
    ? calculateYearlyRentalIncome(rentalIncome)
    : [];

  const data = {
    labels: lastSixYears,
    datasets: [
      {
        label: 'Yearly Rental Income',
        data: yearlyRevenue,
        fill: false,
        borderColor: '#F09950',
        tension: 0.1,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Years',
        },
      },
      y: {
        title: {
          display: true,
          text: `Revenue in ${user?.Currency || 'USD'}`,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box sx={{ mt: 8 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" fontWeight="bold" color="black">
          {t('yearly_revenue_trends')}
        </Typography>

        <Typography variant="body1" sx={{ mt: 2, mb: 4 }} color="textSecondary">
          {t('revenue_trends_visualization')}
        </Typography>

        {isRentalIncomeLoading ? (
          <LoadingSpinner />
        ) : (
          <Line data={data} options={options} />
        )}
      </Paper>
    </Box>
  );
};

export default YearlyChart;
