import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import { api } from '../utils/api';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { AccessRequest } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/dateUtils';
import LoadingSpinner from '../helper/LoadingSpinner';

const AccessRequestPage = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const [accessRequests, setAccessRequests] = useState<AccessRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const rowsPerPage = 15;

  useEffect(() => {
    const fetchAccessRequests = async () => {
      try {
        const response = await api.getAccessRequests();
        setAccessRequests(response);
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessRequests();
  }, []);

  const updateAccessRequest = async (request: AccessRequest) => {
    let newStatus: string;

    if (request.Status === 'Active') {
      newStatus = 'Revoked';
    } else if (request.Status === 'Revoked' || request.Status === 'Pending') {
      newStatus = 'Active';
    } else {
      toast.error(t('invalidStatus'));
      return;
    }

    try {
      await api.updateAccessRequest({ ...request, Status: newStatus });
      toast.success(t('statusUpdated', { status: newStatus })); // Translation applied

      setAccessRequests((prevRequests) =>
        prevRequests.map((r) =>
          r === request ? { ...r, Status: newStatus } : r
        )
      );
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const deleteAccessRequest = async (request: AccessRequest) => {
    try {
      await api.deleteAccessRequest(request.RequestID as number);
      toast.success(t('requestDeleted', { name: request.RequesterName })); // Translation applied

      setAccessRequests((prevRequests) =>
        prevRequests.filter((r) => r !== request)
      );
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const renderStatusIcon = (status: string) => {
    const color =
      status === 'Active'
        ? 'green'
        : status === 'Pending'
          ? 'orange'
          : status === 'Revoked'
            ? 'red'
            : 'transparent';

    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          backgroundColor: color,
          display: 'inline-block',
        }}
      ></div>
    );
  };

  const paginatedRequests = accessRequests.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('accessRequests')} {/* Translation applied */}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('manageRequests')} {/* Translation applied */}
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ mt: 2, maxHeight: '500px', overflow: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('propertyName')}</strong> {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('status')}</strong> {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('requestDate')}</strong> {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('requesterName')}</strong>{' '}
                {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('requesterRole')}</strong>{' '}
                {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('requesterEmail')}</strong>{' '}
                {/* Translation applied */}
              </TableCell>
              <TableCell>
                <strong>{t('actions')}</strong> {/* Translation applied */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRequests.length > 0 ? (
              paginatedRequests.map((request, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                >
                  <TableCell>{request.PropertyName || 'N/A'}</TableCell>
                  <TableCell>{renderStatusIcon(request.Status)}</TableCell>
                  <TableCell>
                    {request.RequestDate
                      ? formatDate(request.RequestDate)
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{request.RequesterName || 'N/A'}</TableCell>
                  <TableCell>{request.RequesterRole || 'N/A'}</TableCell>
                  <TableCell>{request.RequesterEmail || 'N/A'}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Tooltip
                        title={
                          request.Status === 'Approved'
                            ? t('markAsPending')
                            : t('approveRequest')
                        }
                        arrow
                      >
                        <IconButton
                          onClick={() => updateAccessRequest(request)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: '#FF9B5D',
                            },
                          }}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={t('deleteRequest')} arrow>
                        <IconButton
                          color="error"
                          sx={{
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: 'red',
                            },
                          }}
                          onClick={() => deleteAccessRequest(request)}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(accessRequests.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default AccessRequestPage;
