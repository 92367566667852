import React, { createContext, useContext, useState, useEffect } from 'react';
import { api } from '../utils/api';
import { clearAllData } from '../utils/indexedDB';

interface AuthContextType {
  isAuth: boolean;
  isLoading: boolean;
  userRoleType: string | null | undefined;
  subscriptionType: string | null | undefined;
  isSubActive: boolean;
  setAuth: (auth: boolean) => void;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuth, setAuth] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userRoleType, setUserRoleType] = useState<string | null>(null);
  const [subscriptionType, setSubscriptionType] = useState<string | null>(null);
  const [isSubActive, setIsSubActive] = useState<boolean>(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await api.authUser();
        if (!response) throw new Error('Login failed');

        const user = await api.getUserDetails();
        setAuth(true);
        setUserRoleType(user.UserRoleType ?? null);
        setSubscriptionType(user.SubscriptionType ?? null);
        setIsSubActive(!!user.IsActive);
      } catch (error) {
        console.error('Login error:', error);
        setAuth(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  const login = async () => {
    setLoading(true);
    try {
      const response = await api.authUser();
      if (!response) throw new Error('Login failed');

      const user = await api.getUserDetails();
      setAuth(true);
      setUserRoleType(user.UserRoleType ?? null);
      setSubscriptionType(user.SubscriptionType ?? null);
      setIsSubActive(!!user.IsActive);
    } catch (error) {
      console.info('Login error:', error);
      setAuth(false);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      clearAllData()
        .then(() => {
          window.location.href = '/';
          setAuth(false);
          setUserRoleType(null);
          setSubscriptionType(null);
          setIsSubActive(false);
        })
        .catch((error) => {
          console.error('Error clearing IndexedDB:', error.message);
          window.location.href = '/';
        });
    } catch (error) {
      console.info('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isLoading,
        userRoleType,
        subscriptionType,
        isSubActive,
        setAuth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
