import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import BarChart from '@mui/icons-material/BarChart';
import Home from '@mui/icons-material/Home';
import People from '@mui/icons-material/People';
import Android from '@mui/icons-material/Android';
import Apple from '@mui/icons-material/Apple';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: 'center', mt: 4, px: 2 }}>
      {/* Hero Section */}
      <Box component="section">
        <Typography variant="h2" fontWeight="bold">
          {t('heroSection.title')} 🚀
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 4 }}>
          {t('heroSection.subtitle')}
        </Typography>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#EC675D',
              '&:hover': {
                backgroundColor: '#FF9B5D',
              },
            }}
            size="large"
          >
            {t('heroSection.getStarted')}
          </Button>
        </Link>
      </Box>

      {/* Feature Icons */}
      <Grid container spacing={3} sx={{ mt: 6 }} justifyContent="center">
        {[
          {
            icon: <MonetizationOn sx={{ fontSize: 60, color: 'white' }} />,
            label: t('features.automatedRentCollection'),
            bg: 'success.dark',
          },
          {
            icon: <BarChart sx={{ fontSize: 60, color: 'white' }} />,
            label: t('features.powerfulRentalAnalytics'),
            bg: 'secondary.dark',
          },
          {
            icon: <Home sx={{ fontSize: 60, color: 'white' }} />,
            label: t('features.smartPropertyManagement'),
            bg: 'warning.dark',
          },
          {
            icon: <People sx={{ fontSize: 60, color: 'white' }} />,
            label: t('features.tenantTracking'),
            bg: 'info.dark',
          },
        ].map((item, index) => (
          <Grid item key={index}>
            <Avatar sx={{ width: 100, height: 100, backgroundColor: item.bg }}>
              {item.icon}
            </Avatar>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {item.label}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* How It Works Section */}
      <Box component="section" sx={{ mt: 6, textAlign: 'left' }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          {t('howItWorks.title')}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
          <strong>{t('howItWorks.signUp')}</strong> –{' '}
          {t('howItWorks.signUpDescription')} <br />
          <strong>{t('howItWorks.subscription')}</strong> –{' '}
          {t('howItWorks.subscriptionDescription')} <br />
          <strong>{t('howItWorks.addProperties')}</strong> –{' '}
          {t('howItWorks.addPropertiesDescription')} <br />
          <strong>{t('howItWorks.addTenants')}</strong> –{' '}
          {t('howItWorks.addTenantsDescription')} <br />
          <strong>{t('howItWorks.trackPayments')}</strong> –{' '}
          {t('howItWorks.trackPaymentsDescription')} <br />
          <strong>{t('howItWorks.analyzeOptimize')}</strong> –{' '}
          {t('howItWorks.analyzeOptimizeDescription')} <br />
          <strong>{t('howItWorks.chatWithTenants')}</strong> –{' '}
          {t('howItWorks.chatWithTenantsDescription')} <br />
          <strong>{t('howItWorks.propertyManagement')}</strong> –{' '}
          {t('howItWorks.propertyManagementDescription')}
        </Typography>
      </Box>

      {/* Pricing Table */}
      <Box component="section" sx={{ mt: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('pricingPlans.title')}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ mt: 4, maxWidth: 800, margin: 'auto' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>{t('pricingPlans.plan')}</strong>
                </TableCell>
                <TableCell>
                  <strong>{t('pricingPlans.price')}</strong>
                </TableCell>
                <TableCell>
                  <strong>{t('pricingPlans.properties')}</strong>
                </TableCell>
                <TableCell>
                  <strong>{t('pricingPlans.unitsPerProperty')}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  plan: t('pricingPlans.free'),
                  price: t('pricingPlans.prices.free'),
                  properties: t('pricingPlans.property.free'),
                  units: t('pricingPlans.unit.free'),
                },
                {
                  plan: t('pricingPlans.basic'),
                  price: t('pricingPlans.prices.basic'),
                  properties: t('pricingPlans.property.basic'),
                  units: t('pricingPlans.unit.basic'),
                },
                {
                  plan: t('pricingPlans.standard'),
                  price: t('pricingPlans.prices.standard'),
                  properties: t('pricingPlans.property.standard'),
                  units: t('pricingPlans.unit.standard'),
                },
                {
                  plan: t('pricingPlans.premium'),
                  price: t('pricingPlans.prices.premium'),
                  properties: t('pricingPlans.property.premium'),
                  units: t('pricingPlans.unit.premium'),
                },
              ].map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.plan}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.properties}</TableCell>
                  <TableCell>{row.units}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Mobile App Section */}
      <Box component="section" sx={{ mt: 8 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('mobileApp.title')}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 4 }}>
          {t('mobileApp.subtitle')}
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {/* Android Download */}
          <Grid item>
            <a
              href="https://play.google.com/store/apps/details?id=com.genesistech.leasesphere&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar
                sx={{ width: 100, height: 100, backgroundColor: 'green' }}
              >
                <Android sx={{ fontSize: 60, color: 'white' }} />
              </Avatar>
            </a>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {t('mobileApp.androidDownload')}
            </Typography>
            <QRCode
              value="https://play.google.com/store/apps/details?id=com.genesistech.leasesphere&hl=en"
              size={120}
            />
          </Grid>

          {/* iOS Download */}
          <Grid item>
            <a
              href="https://apps.apple.com/app/leasesphere/id123456789"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar
                sx={{ width: 100, height: 100, backgroundColor: 'black' }}
              >
                <Apple sx={{ fontSize: 60, color: 'white' }} />
              </Avatar>
            </a>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {t('mobileApp.iosDownload')}
            </Typography>
            <QRCode
              value="https://apps.apple.com/app/leasesphere/id123456789"
              size={120}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Final Call to Action */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('finalCall.title')}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          {t('finalCall.description')}
        </Typography>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#EC675D',
              '&:hover': {
                backgroundColor: '#FF9B5D',
              },
            }}
            size="large"
          >
            {t('finalCall.getStarted')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default HomePage;
