import { create } from 'zustand';
import { Message } from '../utils/interfaces';

interface MessageStore {
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  isMessageLoading: boolean;
  setLoading: (isMessageLoading: boolean) => void;
}

export const useMessageStore = create<MessageStore>((set) => ({
  messages: [],
  isMessageLoading: false,

  setMessages: (messages: Message[]) => set({ messages }),

  setLoading: (isMessageLoading: boolean) => set({ isMessageLoading }),
}));
