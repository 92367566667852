import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('privacyPolicy.title')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section1.header')}</strong>{' '}
          {t('privacyPolicy.section1.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section2.header')}</strong>{' '}
          {t('privacyPolicy.section2.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section3.header')}</strong>{' '}
          {t('privacyPolicy.section3.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section4.header')}</strong>{' '}
          {t('privacyPolicy.section4.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section5.header')}</strong>{' '}
          {t('privacyPolicy.section5.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section6.header')}</strong>{' '}
          {t('privacyPolicy.section6.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section7.header')}</strong>{' '}
          {t('privacyPolicy.section7.content')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>{t('privacyPolicy.section8.header')}</strong>{' '}
          {t('privacyPolicy.section8.content')
            .split(t('privacyPolicy.section8.paystackTerms'))
            .map((part, index, array) =>
              index < array.length - 1 ? (
                <>
                  {part}
                  <a
                    href="https://paystack.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>{t('privacyPolicy.section8.paystackTerms')}</u>
                  </a>
                </>
              ) : (
                part
              )
            )}
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
