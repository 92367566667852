import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { api } from '../utils/api';
import { Token } from '../utils/interfaces';
import LoadingSpinner from '../helper/LoadingSpinner';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams<Token>();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );

  useEffect(() => {
    if (token) {
      api
        .verifyEmailLink(token)
        .then((response) => {
          if (response.success) {
            setStatus('success');
          } else {
            setStatus('error');
          }
        })
        .catch(() => {
          setStatus('error');
        });
    } else {
      setStatus('error');
    }
  }, [token]);

  return (
    <Box sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 4,
          width: '100%',
          maxWidth: 400,
          textAlign: 'center',
        }}
      >
        {status === 'loading' && (
          <>
            <LoadingSpinner />
            <Typography variant="h6" fontWeight="bold">
              {t('verify_email.verifying')}
            </Typography>
          </>
        )}

        {status === 'success' && (
          <>
            <CheckCircle sx={{ fontSize: 60, color: '#4caf50', mb: 2 }} />
            <Typography variant="h5" fontWeight="bold" color="success.main">
              {t('verify_email.success')}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('verify_email.close_page')}
            </Typography>
          </>
        )}

        {status === 'error' && (
          <>
            <ErrorOutline sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
            <Alert severity="error" sx={{ mb: 2 }}>
              {t('verify_email.error')}
            </Alert>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default VerifyEmail;
