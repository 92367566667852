export const validateEmail = (email: string): boolean => {
  const patternEmail = new RegExp(
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
  );
  return patternEmail.test(email);
};

export const validatePassword = (password: string): string | null => {
  if (password.length < 8) {
    return 'password_tip_length'; // Password must be at least 8 characters long
  }

  if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
    return 'password_tip_case'; // Include both lower and upper case characters
  }

  if (
    !/[0-9]/.test(password) ||
    !/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(password)
  ) {
    return 'password_tip_number_symbol'; // Include at least one number and one symbol
  }

  return null;
};
