import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Property as PropertyProps } from '../utils/interfaces';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 10,
  p: 4,
  borderRadius: 2,
  maxHeight: '50vh',
  overflowY: 'auto',
};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  width: '100%',
};

interface PropertyModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (propertyDetails: PropertyProps) => void;
  propertyDetails: PropertyProps;
  validationErrors: { [key: string]: string };
  setPropertyDetails: React.Dispatch<React.SetStateAction<PropertyProps>>;
  selectedType: string;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  isEditMode: boolean;
}

const PropertyModal = ({
  open,
  onClose,
  onSave,
  propertyDetails,
  validationErrors,
  setPropertyDetails,
  selectedType,
  setSelectedType,
  isEditMode,
}: PropertyModalProps) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Fields that should only accept whole numbers
    const integerFields = new Set([
      'NumberOfUnits',
      'NumberOfBathrooms',
      'NumberOfBedrooms',
    ]);

    if (integerFields.has(name) && !/^\d*$/.test(value)) {
      return;
    }

    // Allow numeric values with decimals for Price
    if (name === 'Price' && !/^\d*\.?\d*$/.test(value)) {
      return;
    }

    setPropertyDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isNewProperty = !propertyDetails || !propertyDetails.PropertyID;

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: 'transparent' },
      }}
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" gutterBottom>
          {isEditMode
            ? isNewProperty
              ? t('addNewProperty')
              : t('editProperty')
            : t('viewProperty')}
        </Typography>

        <Box sx={gridStyle}>
          <TextField
            label={t('propertyName')}
            fullWidth
            margin="normal"
            name="PropertyName"
            value={propertyDetails.PropertyName}
            onChange={handleChange}
            error={!!validationErrors.PropertyName}
            helperText={validationErrors.PropertyName}
            disabled={!isEditMode}
          />
          <TextField
            label={t('price')}
            fullWidth
            margin="normal"
            name="Price"
            type="tel"
            value={propertyDetails.Price}
            onChange={handleChange}
            error={!!validationErrors.Price}
            helperText={validationErrors.Price}
            disabled={!isEditMode}
            slotProps={{
              input: {
                inputMode: 'decimal',
              },
            }}
          />
          <TextField
            label={t('address')}
            fullWidth
            margin="normal"
            name="Location"
            value={propertyDetails.Location}
            onChange={handleChange}
            error={!!validationErrors.Location}
            helperText={validationErrors.Location}
            disabled={!isNewProperty}
          />
          <TextField
            label={t('numberOfUnits')}
            fullWidth
            margin="normal"
            name="NumberOfUnits"
            type="tel"
            value={propertyDetails.NumberOfUnits}
            onChange={handleChange}
            error={!!validationErrors.NumberOfUnits}
            helperText={validationErrors.NumberOfUnits}
            disabled={!isEditMode}
            slotProps={{
              input: {
                inputMode: 'numeric',
              },
            }}
          />
          {(isNewProperty || !isEditMode) && selectedType !== 'Hostel' && (
            <>
              <TextField
                label={t('numberOfBedrooms')}
                fullWidth
                margin="normal"
                name="NumberOfBedrooms"
                type="tel"
                value={propertyDetails.NumberOfBedrooms}
                onChange={handleChange}
                error={!!validationErrors.NumberOfBedrooms}
                helperText={validationErrors.NumberOfBedrooms}
                disabled={!isEditMode}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                  },
                }}
              />
              <TextField
                label={t('numberOfBathrooms')}
                fullWidth
                margin="normal"
                name="NumberOfBathrooms"
                type="tel"
                value={propertyDetails.NumberOfBathrooms}
                onChange={handleChange}
                error={!!validationErrors.NumberOfBathrooms}
                helperText={validationErrors.NumberOfBathrooms}
                disabled={!isEditMode}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                  },
                }}
              />
            </>
          )}

          {isNewProperty && (
            <FormControl
              fullWidth
              margin="normal"
              disabled={!isEditMode}
              error={!!validationErrors.PropertyType}
            >
              <InputLabel>{t('propertyType')}</InputLabel>
              <Select
                name="PropertyType"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                  setPropertyDetails((prev) => ({
                    ...prev,
                    PropertyType: e.target.value,
                  }));
                }}
              >
                <MenuItem value="Apartment">{t('apartment')}</MenuItem>
                <MenuItem value="House">{t('house')}</MenuItem>
                <MenuItem value="Hostel">{t('hostel')}</MenuItem>
              </Select>
              {validationErrors.PropertyType && (
                <FormHelperText>{validationErrors.PropertyType}</FormHelperText>
              )}
            </FormControl>
          )}
        </Box>

        <Box
          sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}
        >
          {isEditMode ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  '&:hover': {
                    backgroundColor: '#B22222',
                    color: 'white',
                  },
                }}
                onClick={onClose}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: '#EC675D',
                  '&:hover': {
                    backgroundColor: '#FF9B5D',
                  },
                }}
                startIcon={<SaveIcon />}
                onClick={() => onSave(propertyDetails)}
              >
                {propertyDetails.PropertyID ? t('update') : t('save')}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              sx={{
                '&:hover': {
                  backgroundColor: '#B22222',
                  color: 'white',
                },
              }}
              startIcon={<CloseIcon />}
              onClick={onClose}
            >
              {t('close')}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PropertyModal;
