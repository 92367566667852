import React, { useState, useEffect } from 'react';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import TrashIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';
import SendIcon from '@mui/icons-material/Send';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import TableBody from '@mui/material/TableBody';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { formatDateForInput, reverseDate } from '../utils/dateUtils';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { useFetchTenants } from '../hooks/tenant.hooks';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import { Message, Tenant } from '../utils/interfaces';
import { useFetchProperties } from '../hooks/property.hooks';
import { usePropertyStore } from '../stores/PropertyStore';
import { useTenantStore } from '../stores/TenantStore';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TenantModal from './TenantModal';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import { api } from '../utils/api';
import { deleteKeyStore } from '../utils/indexedDB';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { validateEmail } from '../helper/validation';
import { motion } from 'framer-motion';
import { useAuth } from '../helper/AuthContext';
import { useTranslation } from 'react-i18next';
import { SubscriptionType, UserRoleType } from '../types/types';
import { useUserStore } from '../stores/UserStore';
import LoadingSpinner from '../helper/LoadingSpinner';

const TenantTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const errorMessages = new ErrorMessages();
  const { subscriptionType, userRoleType } = useAuth();
  const [statusFilter, setStatusFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
  const [buildingFilter, setBuildingFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [refreshKey, setRefreshKey] = useState(0);
  const { user } = useUserStore();
  const isCaretaker = userRoleType === UserRoleType.Caretaker;

  const {
    isError: isPropertyError,
    error: propertyError,
    isLoading: isPropertyLoading,
  } = useFetchProperties();

  const {
    isError: isTenantError,
    error: tenantError,
    refetch: refetchTenants,
  } = useFetchTenants();

  const { properties } = usePropertyStore();
  const { tenants, isTenantLoading } = useTenantStore();

  useEffect(() => {
    if (isPropertyLoading || isTenantLoading) {
      <LoadingSpinner />;
      return;
    }
  }, [isPropertyLoading, isTenantLoading]);

  useEffect(() => {
    if (isPropertyError) {
      ErrorHandler.handleError(propertyError, errorMessages, toast);
    }
    if (isTenantError) {
      ErrorHandler.handleError(tenantError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPropertyError,
    propertyError,
    isTenantError,
    tenantError,
    errorMessages,
    toast,
  ]);

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleRefetch = async () => {
    await refetchTenants();
  };

  const handleClearSearch = () => {
    setUnitFilter('');
  };

  const handleOpenModal = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSubject('');
    setDescription('');
  };

  const handleSend = () => {
    if (
      !selectedTenant ||
      !selectedTenant.Email ||
      !validateEmail(selectedTenant.Email) ||
      selectedTenant.Email.endsWith('@example.com') ||
      !selectedTenant.TenantID
    ) {
      toast.info(t('email_not_valid'));
      return;
    }

    const messageData: Message = {
      MessageID: 0,
      Subject: subject,
      Description: description,
      TenantID: selectedTenant.TenantID,
      FirstName: selectedTenant.FirstName,
      LastName: selectedTenant.LastName,
      Email: selectedTenant.Email,
      DefaultLanguage: user?.DefaultLanguage || 'en',
    };

    api
      .sendMessage(messageData)
      .then(async () => {
        await deleteKeyStore('messages');
        toast.success(t('message_sent'));
        handleCloseModal();
      })
      .catch((error) => {
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseTenantModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setSelectedFile(file);
      handleCloseMenu(); // Close the menu after file selection
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.info(t('nofile'));
      return;
    }

    useTenantStore.getState().setLoading(true);
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

    const processFile = async (rows: Tenant[]): Promise<boolean> => {
      const processedUnits: Set<string> = new Set();
      const processedPropertyUnitCombos: Set<string> = new Set(); // Track unique PropertyID + Unit combination
      let limit = 10;

      // Set limit based on subscription type
      if (subscriptionType === 'Basic') {
        limit = 30;
      } else if (subscriptionType === 'Standard') {
        limit = 80;
      } else if (subscriptionType === 'Premium') {
        limit = rows.length; // No limit for premium users
      }

      let propertyID = '';
      let allRowsProcessedSuccessfully = true;

      for (let index = 0; index < rows.length; index++) {
        if (index >= limit) {
          toast.warning(
            `Allowed processing limit reached for ${user?.SubscriptionType} subscription.`
          );
          break;
        }

        const row = rows[index];

        // Check for duplicate Unit (if the same unit is already processed)
        //@ts-ignore
        if (row.Unit) {
          if (processedUnits.has(row.Unit)) {
            toast.error(
              `Duplicate Unit ID '${row.Unit}' found in row ${index + 2}.`
            );
            continue;
          }
          processedUnits.add(row.Unit); // Add Unit to processed units
        }

        const uniqueComboKey = `${row.PropertyID}-${row.Unit}-${row.Complex}`;

        if (processedPropertyUnitCombos.has(uniqueComboKey)) {
          toast.error(
            `Duplicate PropertyID-Unit combination '${row.PropertyID} - ${row.Unit}' found in row ${index + 2}.`
          );
          continue;
        }

        processedPropertyUnitCombos.add(uniqueComboKey); // Add the unique combo to the set to track

        if (row.PropertyID) {
          propertyID = row.PropertyID;
        }

        const rowWithDefaults: Tenant = {
          ...row,
          PropertyID: row.PropertyID || 'noGUIDSupplied',
          file: null,
        };

        try {
          await api.createTenant(rowWithDefaults);
        } catch (error) {
          //@ts-ignore
          toast.error(error.message);
          allRowsProcessedSuccessfully = false;
        }
      }

      // Post-processing cleanup
      await deleteKeyStore('tenants');
      await deleteKeyStore('properties');
      if (propertyID) {
        await deleteKeyStore(`rooms_${propertyID}`);
      }

      return allRowsProcessedSuccessfully;
    };

    const readAndProcessFile = async () => {
      try {
        let parsedData: Tenant[] = [];
        let processingSuccessful = false;

        if (fileExtension === 'csv') {
          parsedData = await new Promise<Tenant[]>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
              const csvText = event.target?.result as string;
              parsedData = parseCSV(csvText);
              resolve(parsedData);
            };
            reader.onerror = reject;
            reader.readAsText(selectedFile);
          });
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
          parsedData = await new Promise<Tenant[]>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
              const data = new Uint8Array(event.target?.result as ArrayBuffer);
              const workbook = XLSX.read(data, { type: 'array' });
              const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
              const parsedData = XLSX.utils.sheet_to_json(firstSheet, {
                header: 1,
              });

              const formattedData = (parsedData as any[][])
                .slice(1)
                .filter((row) => row.some((cell) => cell?.trim()))
                .map((row) => ({
                  PropertyID: row[0] || 'defaultGUID',
                  FirstName: row[1],
                  LastName: row[2],
                  Unit: row[4],
                  Complex: row[5],
                  Email: row[6],
                  Telephone: row[7],
                  StartDate: row[8],
                  EndDate: row[9],
                  file: null,
                }));
              resolve(formattedData);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(selectedFile);
          });
        } else {
          toast.warning(t('upload_valid_file'));
          return;
        }

        processingSuccessful = await processFile(parsedData);
        if (processingSuccessful) toast.success(t('success'));
      } catch (error) {
        ErrorHandler.handleError(error, errorMessages, toast);
      } finally {
        useTenantStore.getState().setLoading(false);
        handleRefetch();
      }
    };

    await readAndProcessFile();
  };

  const removeAllSpaces = (value: string): string => value.replace(/\s+/g, '');

  const parseCSV = (csvText: string) => {
    const lines = csvText.split('\n').filter((line) => line.trim() !== '');
    const parsedData: Tenant[] = [];

    try {
      lines.slice(1).forEach((line, index) => {
        const values = line.split(',').map((val) => val.trim()); // Trim all values

        const rowData: Partial<Tenant> = {
          PropertyID: values[0] || '',
          FirstName: values[1] || '',
          LastName: values[2] || '',
          Unit: removeAllSpaces(values[3]?.trim()) || '',
          Complex: values[4] || '',
          Email: values[5] || '',
          Telephone: values[6] || '',
          StartDate: values[7] || '',
          EndDate: values[8] || '',
        };

        if (
          !rowData.PropertyID ||
          !rowData.FirstName ||
          !rowData.LastName ||
          !rowData.Unit ||
          !rowData.StartDate ||
          !rowData.EndDate
        ) {
          toast.error(
            `Missing required fields in row ${index + 2}. Cancelling parsing.`
          );
          throw new Error(
            `Missing required fields in row ${index + 2}. Cancelling parsing.`
          );
        }

        //@ts-ignore
        rowData.id = index + 1; // Assign unique ID

        parsedData.push(rowData as Tenant);
      });

      return parsedData;
    } catch (error) {
      toast.error(t('failed_reading_csv') + error);
      return [];
    }
  };

  const handleAddNewTenant = () => {
    setIsEditMode(true);
    setSelectedTenant({} as Tenant);
    setShowModal(true);
    handleCloseMenu();
  };

  const handleViewTenant = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setIsEditMode(false);
    setShowModal(true);
  };

  const handleEditTenant = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleSaveTenant = async (tenant: Tenant | null) => {
    try {
      const validationErrors: { [key: string]: string } = {};
      if (!tenant) {
        toast.error('Missing required tenant information');
        return;
      }

      const requiredFields: { key: keyof typeof tenant; message: string }[] = [
        { key: 'FirstName', message: 'First Name is required' },
        { key: 'LastName', message: 'Last Name is required' },
        { key: 'Email', message: 'Email is required' },
        { key: 'Unit', message: 'Unit is required' },
        { key: 'StartDate', message: 'Start Date is required' },
        { key: 'EndDate', message: 'End Date is required' },
        { key: 'PropertyName', message: 'Property Name is required' },
      ];

      requiredFields.forEach(({ key, message }) => {
        if (!tenant[key]) {
          validationErrors[key] = message;
        }
      });

      if (Object.keys(validationErrors).length > 0) {
        setValidationErrors(validationErrors);
        return;
      }

      const startDate = new Date(tenant.StartDate);
      const endDate = new Date(tenant.EndDate);

      // Check if dates are valid
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        toast.error(t('invalid_date_input'));
        return;
      }

      if (endDate < startDate) {
        toast.error(t('end_date_before_start_date'));
        return;
      }

      // Convert to YYYY-MM-DD without altering the date components
      const formatDate = (date: Date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      };

      const correctedTenantData = {
        ...tenant,
        StartDate: formatDate(startDate),
        EndDate: formatDate(endDate),
      };

      if (tenant.TenantID) {
        if (!tenant.PropertyID || !tenant.TenantID) {
          toast.error('Missing required tenant information');
          return;
        }
        // **UPDATE NEW TENANT**
        await api.updateTenant(
          tenant.PropertyID,
          tenant.TenantID,
          correctedTenantData
        );
      } else {
        // **CREATE NEW TENANT**
        await api.createTenant(correctedTenantData);
      }

      toast.success('Success');
      setShowModal(false);
      await deleteKeyStore('tenants');
      await deleteKeyStore('properties');
      if (tenant?.PropertyID) {
        await deleteKeyStore(`rooms_${tenant.PropertyID}`);
      }
      handleRefetch();
      setRefreshKey((prev) => prev + 1);
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handleDeleteTenant = async (tenantId: number, propertyId?: string) => {
    if (!tenantId || !propertyId) {
      toast.error('Invalid tenant or property ID.');
      return;
    }

    try {
      await api.deleteTenant(propertyId, tenantId);
      await deleteKeyStore('tenants');
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${propertyId}`);
      toast.success(t('delete_success'));
      handleRefetch();
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handleAutoRenew = async (tenant: Tenant) => {
    if (
      !tenant.PropertyID ||
      !tenant.TenantID ||
      !tenant.StartDate ||
      !tenant.EndDate
    ) {
      toast.error('Property ID, Start date, or End date is undefined');
      return;
    }

    const confirmation = window.confirm(t('auto_renew_confirmation'));

    if (!confirmation) return;

    const currentStartDate = new Date(tenant.StartDate);
    const currentEndDate = new Date(tenant.EndDate);

    // Calculate new end date by adding 1 year
    const newEndDate = new Date(
      currentEndDate.setFullYear(currentEndDate.getFullYear() + 1)
    );

    // StartDate remains unchanged since it's an auto-renewal
    const startDateFormatted = formatDateForInput(
      currentStartDate.toISOString()
    );
    const endDateFormatted = formatDateForInput(newEndDate.toISOString());

    if (new Date(startDateFormatted) > new Date(endDateFormatted)) {
      toast.error(t('end_date_error'));
      return;
    }

    try {
      await api.updateTenant(tenant.PropertyID, tenant.TenantID, {
        ...tenant,
        StartDate: startDateFormatted,
        EndDate: endDateFormatted,
      });

      await deleteKeyStore('tenants');
      toast.success(t('updated'));

      handleRefetch();
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handleOpenDialog = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedTenant(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedTenant?.TenantID && selectedTenant?.PropertyID) {
      await handleDeleteTenant(
        selectedTenant.TenantID,
        selectedTenant.PropertyID
      );
    }
    handleCloseDialog();
  };

  const filteredTenants = tenants?.filter((tenant) => {
    const filterStatusLower = statusFilter ? t(statusFilter.toLowerCase()) : '';
    const translatedStatus = tenant.Status
      ? t(tenant.Status.toLowerCase())
      : '';

    // Ensure tenant.PropertyName and buildingFilter are valid before using t()
    const matchesBuilding = buildingFilter
      ? tenant.PropertyName && t(tenant.PropertyName) === t(buildingFilter)
      : true;

    const matchesStatus = statusFilter
      ? translatedStatus.toLowerCase() === filterStatusLower.toLowerCase()
      : true;

    const matchesEndDate = endDateFilter
      ? new Date(tenant.EndDate) <= endDateFilter
      : true;

    const matchesUnit = unitFilter
      ? tenant.Unit?.toLowerCase().includes(unitFilter.toLowerCase())
      : true;

    return matchesStatus && matchesEndDate && matchesBuilding && matchesUnit;
  });

  const resetForm = () => {
    setStatusFilter('');
    setEndDateFilter(null);
    setBuildingFilter('');
    setValidationErrors({});
    setUnitFilter('');
  };

  const getStatusColor = (status: string | undefined) => {
    if (!status) return '#000000';

    const statusTranslations: { [key: string]: string } = {
      actif: 'active', // French for active
      'en cours': 'due', // French for due
      'en retard': 'past due', // French for past due
    };

    // Normalize the status (convert to lowercase and check for translations)
    const normalizedStatus = status.toLowerCase();
    const englishStatus =
      statusTranslations[normalizedStatus] || normalizedStatus;

    switch (englishStatus) {
      case 'active':
        return '#28a745'; // Green for Active
      case 'due':
        return '#ffc107'; // Yellow for Due
      case 'past due':
        return '#dc3545'; // Red for Past Due
      default:
        return '#000000'; // Default color for any unknown status
    }
  };

  return (
    <Box key={refreshKey} sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('tenants')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('tenant_count', { count: tenants?.length })}
      </Typography>

      {isTenantLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <LoadingSpinner />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mb: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Status Filter */}
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>{t('status')}</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">{t('all')}</MenuItem>
            <MenuItem value="Active">{t('active')}</MenuItem>
            <MenuItem value="Past Due">{t('past_due')}</MenuItem>
            <MenuItem value="Due">{t('due')}</MenuItem>
          </Select>
        </FormControl>

        {/* End Date Filter */}
        <TextField
          label={t('end_date')}
          type="date"
          value={endDateFilter ? endDateFilter.toISOString().split('T')[0] : ''}
          onChange={(e) =>
            setEndDateFilter(e.target.value ? new Date(e.target.value) : null)
          }
          InputLabelProps={{ shrink: true }}
        />

        {/* Building Filter */}
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>{t('building')}</InputLabel>
          <Select
            value={buildingFilter}
            onChange={(e) => setBuildingFilter(e.target.value)}
          >
            <MenuItem value="">{t('all')}</MenuItem>
            {properties?.map((property) => (
              <MenuItem key={property.PropertyID} value={property.PropertyName}>
                {property.PropertyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Unit Filter */}
        <TextField
          label={t('unit')}
          value={unitFilter}
          onChange={(e) => setUnitFilter(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: unitFilter ? 'visible' : 'hidden' }}
                onClick={handleClearSearch}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />

        {/* Reset and Add Icon buttons */}
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={resetForm}
            sx={{ height: '40px' }}
          >
            {t('reset')}
          </Button>

          <motion.div
            animate={{ rotate: anchorEl ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickButton}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </motion.div>
        </Box>
      </Box>

      {/* Dropdown Menu */}
      {!isCaretaker && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleAddNewTenant}>{t('new')}</MenuItem>
          {subscriptionType === SubscriptionType.Premium && (
            <MenuItem
              onClick={() => document.getElementById('file-input')?.click()}
            >
              {t('upload_csv')}
            </MenuItem>
          )}
          {subscriptionType === SubscriptionType.Premium && (
            <MenuItem component={Link} to="/messages" onClick={handleCloseMenu}>
              {t('view_messages')}
            </MenuItem>
          )}
          {/* Hidden File Input for CSV Upload */}
          <input
            id="file-input"
            type="file"
            style={{ display: 'none' }}
            accept=".csv,.xlsx,.xls"
            onChange={handleFileSelection}
          />
        </Menu>
      )}

      {/* Modal for Send Email */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 500, // Increased width
            backgroundColor: 'white',
            borderRadius: 2,
            padding: 3,
            boxShadow: 24,
          }}
        >
          <h2>{t('send_email')}</h2>

          <TextField
            label={t('subject')}
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            label={t('description')}
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Button onClick={handleCloseModal} color="secondary">
              {t('close')}
            </Button>
            <Button onClick={handleSend} color="primary">
              {t('send')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{ mt: 4, maxHeight: '400px', overflow: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('first_name')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('last_name')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('unit')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('start_date')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('end_date')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('building')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('status')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('actions')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTenants?.length ? (
              filteredTenants
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tenant, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    <TableCell>{tenant.FirstName}</TableCell>
                    <TableCell>{tenant.LastName}</TableCell>
                    <TableCell>{tenant.Unit}</TableCell>
                    <TableCell>{reverseDate(tenant.StartDate)}</TableCell>
                    <TableCell>{reverseDate(tenant.EndDate)}</TableCell>
                    <TableCell>{tenant.PropertyName}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: getStatusColor(tenant.Status),
                          display: 'inline-block',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={t('view')}>
                        <IconButton
                          color="primary"
                          onClick={() => handleViewTenant(tenant)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#FF9B5D',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: 'white',
                            },
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>

                      {!isCaretaker && (
                        <>
                          <Tooltip title={t('edit')}>
                            <IconButton
                              color="primary"
                              onClick={() => handleEditTenant(tenant)}
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#FF9B5D',
                                },
                                '& svg': {
                                  color: 'black',
                                },
                                '&:hover svg': {
                                  color: 'white',
                                },
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          {subscriptionType !== SubscriptionType.Basic && (
                            <Tooltip title={t('message')}>
                              <IconButton
                                color="primary"
                                onClick={() => handleOpenModal(tenant)}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#FF9B5D',
                                  },
                                  '& svg': {
                                    color: 'black',
                                  },
                                  '&:hover svg': {
                                    color: 'white',
                                  },
                                }}
                              >
                                <SendIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title={t('renew_lease')}>
                            <IconButton
                              color="primary"
                              onClick={() => handleAutoRenew(tenant)}
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#FF9B5D',
                                },
                                '& svg': {
                                  color: 'black',
                                },
                                '&:hover svg': {
                                  color: 'white',
                                },
                              }}
                            >
                              <AutorenewIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={t('delete')}>
                            <IconButton
                              color="error"
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#fff',
                                },
                                '& svg': {
                                  color: 'black',
                                },
                                '&:hover svg': {
                                  color: 'red',
                                },
                              }}
                              onClick={() => handleOpenDialog(tenant)}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={tenants?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Delete Confirmation Modal */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{t('confirm_deletion')}</DialogTitle>
        <DialogContent>
          {t('delete_tenant_confirmation', {
            lastName: selectedTenant?.LastName,
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal */}
      {showModal && (
        <TenantModal
          open={showModal}
          onClose={handleCloseTenantModal}
          tenant={selectedTenant || ({} as Tenant)}
          isEditMode={isEditMode}
          onSave={handleSaveTenant}
          validationErrors={validationErrors}
        />
      )}
    </Box>
  );
};

export default TenantTable;
