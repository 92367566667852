import { create } from 'zustand';
import { Tenant } from '../utils/interfaces';

interface TenantStore {
  tenants: Tenant[] | null;
  setTenants: (tenants: Tenant[]) => void;
  isTenantLoading: boolean;
  setLoading: (isTenantLoading: boolean) => void;
}

export const useTenantStore = create<TenantStore>((set) => ({
  tenants: null,
  isTenantLoading: false,

  // Function to set tenants in the store
  setTenants: (tenants: Tenant[]) => set({ tenants }),

  // Function to set isTenantLoading state
  setLoading: (isTenantLoading: boolean) => set({ isTenantLoading }),
}));
