export enum UserRoleType {
  Landlord = 'Landlord',
  Tenant = 'Tenant',
  Caretaker = 'Caretaker',
}

export enum SubscriptionType {
  Free = 'Free',
  Basic = 'Basic',
  Standard = 'Standard',
  Premium = 'Premium',
}
