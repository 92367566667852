import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { toast } from 'react-toastify';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { useFetchUser, useUpdateUserProfile } from '../hooks/user.hooks';
import { useUserStore } from '../stores/UserStore';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { User } from '../utils/interfaces';
import { SelectChangeEvent } from '@mui/material/Select';
import { countries } from '../utils/countries';
import avatars from '../utils/avatars';
import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { api } from '../utils/api';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../helper/AuthContext';
import LoadingSpinner from '../helper/LoadingSpinner';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const errorMessages = new ErrorMessages();
  const { setUser } = useUserStore();
  const updateUserProfile = useUpdateUserProfile();

  const {
    data: profile,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
    refetch,
  } = useFetchUser();

  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(profile?.Avatar || '');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (profile) {
      setUpdatedProfile(profile);
      setSelectedAvatar(profile?.Avatar || '');
    }
  }, [profile]);

  useEffect(() => {
    if (isUserLoading) {
      <LoadingSpinner />;
      return;
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserError]);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteAccount();
    setOpenDeleteDialog(false);
  };

  const extractAvatarName = (path: string | undefined): string | null => {
    const match = path?.match(/memo_\d+/);
    return match ? match[0] : null;
  };

  const avatarKey = extractAvatarName(selectedAvatar);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Allow only numeric values for the Phone field
    if (name === 'Phone' && !/^\d*$/.test(value)) {
      return;
    }

    setUpdatedProfile(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        }) as User
    );
  };

  const handleSelectChange = (e: SelectChangeEvent<string>, field: string) => {
    const value = e.target.value;

    setUpdatedProfile((prev) => {
      const updatedData = { ...prev, [field]: value };

      if (field === 'Country') {
        const selectedCountry = countries.find((c) => c.name === value);
        if (selectedCountry) {
          updatedData.Currency = selectedCountry.currency;
          updatedData.Country = selectedCountry.name;
        }
      }

      return updatedData as User;
    });
  };

  const handleAvatarSelect = (avatar: string) => {
    setSelectedAvatar(avatar);
    setAvatarModalOpen(false);
  };

  const handleRefetch = () => {
    refetch().catch((error) => {
      console.error('Error refetching tenants:', error);
    });
  };

  const handleDeleteAccount = async () => {
    if (!profile?.Email) {
      toast.error(t('userEmailMissing'));
      return;
    }

    try {
      api
        .deleteAccount(profile.Email)
        .then(() => {
          toast.success('success');
          logout();
        })
        .catch((error) => {
          toast.warning(error.message);
        });
      toast.success(t('accountDeleted'));
    } catch (error) {
      toast.error(t('errorDeletingAccount'));
      console.error('Error deleting account:', error);
    }
  };

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onSave = async () => {
    if (updatedProfile) {
      const profileWithAvatar = {
        ...updatedProfile,
        Avatar: selectedAvatar,
      };

      await api.uploadAvatar(profileWithAvatar);
      updateUserProfile.mutate(profileWithAvatar, {
        onSuccess: () => {
          setUser(profileWithAvatar);
          handleRefetch();
        },
        onError: (error) => {
          // Handle error (e.g., toast.error)
        },
      });
      setIsEditMode(false);
    } else {
      toast.error(t('profileDataMissing'));
    }
  };

  const onCancel = () => {
    setIsEditMode(false);
    setSelectedAvatar(profile?.Avatar || '');
    setUpdatedProfile(profile);
  };

  if (isUserLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold" gutterBottom>
        {t('userProfile')}
      </Typography>

      <Paper sx={{ maxWidth: 600, margin: '0 auto', padding: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {avatarKey && avatars[avatarKey] ? (
                <Avatar
                  alt="User Avatar"
                  src={avatars[avatarKey]}
                  sx={{ width: 150, height: 150 }}
                />
              ) : (
                <Avatar
                  sx={{ width: 150, height: 150, bgcolor: 'primary.main' }}
                >
                  {profile?.Username?.[0]?.toUpperCase() || 'U'}
                </Avatar>
              )}
            </Box>

            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="h6">
                {isEditMode ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="Fullname"
                    value={updatedProfile?.Fullname || ''}
                    onChange={handleInputChange}
                  />
                ) : (
                  profile?.Fullname || t('fullName')
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {!isEditMode && <>{profile?.UserRoleType}</>}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {!isEditMode && <>{profile?.Email || t('userRole')}</>}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              {isEditMode && (
                <>
                  <IconButton
                    sx={{ mt: 2, mr: 2 }}
                    color="primary"
                    onClick={() => setAvatarModalOpen(true)}
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </>
              )}

              {!isEditMode && (
                <Tooltip title={t('deleteAccount')} arrow>
                  <IconButton
                    sx={{
                      mr: 2,
                      '&:hover': {
                        backgroundColor: '#fff',
                      },
                      '& svg': {
                        color: 'black',
                      },
                      '&:hover svg': {
                        color: 'red',
                      },
                    }}
                    onClick={handleDeleteClick}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}

              {!isEditMode && (
                <Tooltip title={t('editAccount')} arrow>
                  <IconButton
                    sx={{
                      '&:hover': {
                        backgroundColor: '#FF9B5D',
                      },
                      '& svg': {
                        color: 'black',
                      },
                      '&:hover svg': {
                        color: 'white',
                      },
                    }}
                    onClick={onEdit}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography variant="h6">{t('userDetails')}</Typography>
            <Box sx={{ mt: 2 }}>
              {profile?.Phone && (
                <Typography variant="body1">
                  <strong>{t('phone')}:</strong>
                  {isEditMode ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Phone"
                      value={updatedProfile?.Phone || ''}
                      onChange={handleInputChange}
                      type="tel"
                      slotProps={{
                        input: {
                          inputMode: 'numeric',
                        },
                      }}
                    />
                  ) : (
                    profile?.Phone
                  )}
                </Typography>
              )}
              {isEditMode ? (
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="Address"
                    label={t('address')}
                    value={updatedProfile?.Address || ''}
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="City"
                    label={t('city')}
                    value={updatedProfile?.City || ''}
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="PostalCode"
                    label={t('postalcode')}
                    value={updatedProfile?.PostalCode || ''}
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    <strong>{t('gender')}:</strong> {profile?.Gender || 'N/A'}
                  </Typography>
                </>
              )}
              {isEditMode ? (
                <>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>{t('gender')}</InputLabel>
                    <Select
                      value={updatedProfile?.Gender || ''}
                      onChange={(e) => handleSelectChange(e, 'Gender')}
                      label={t('gender')}
                    >
                      <MenuItem value="Male">{t('male')}</MenuItem>
                      <MenuItem value="Female">{t('female')}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>{t('country')}</InputLabel>
                    <Select
                      value={updatedProfile?.Country || ''}
                      onChange={(e) => handleSelectChange(e, 'Country')}
                      label={t('country')}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.name} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    <strong>{t('address')}:</strong> {profile?.Address || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    <strong>{t('city')}:</strong> {profile?.City || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    <strong>{t('postalcode')}:</strong>{' '}
                    {profile?.PostalCode || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    <strong>{t('country')}:</strong> {profile?.Country || 'N/A'}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mt: 4 }}>
        {isEditMode && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mr: 2,
                backgroundColor: '#EC675D',
                '&:hover': {
                  backgroundColor: '#FF9B5D',
                },
              }}
              onClick={onSave}
            >
              {t('saveProfile')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                '&:hover': {
                  backgroundColor: '#B22222',
                  color: 'white',
                },
              }}
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
          </>
        )}
      </Box>

      <Modal
        open={avatarModalOpen}
        onClose={() => setAvatarModalOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            p: 3,
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '400px',
            maxWidth: '90%',
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t('selectAvatar')}
          </Typography>
          <ImageList
            sx={{ width: '100%', height: 300 }}
            cols={3}
            rowHeight={100}
          >
            {Object.keys(avatars).map((avatarKey) => (
              <ImageListItem
                key={avatarKey}
                onClick={() => handleAvatarSelect(avatars[avatarKey])}
              >
                <img src={avatars[avatarKey]} alt={avatarKey} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Modal>

      <Dialog open={openDeleteDialog} onClose={handleDialogClose}>
        <DialogTitle>{t('confirmDeletion')}</DialogTitle>
        <DialogContent>
          <Typography>{t('confirmDeletionText')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('cancelDeletion')}
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;
