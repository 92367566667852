import { create } from 'zustand';
import { RentalIncomeResponse } from '../utils/interfaces';

interface RentalIncomeStore {
  rentalIncome: RentalIncomeResponse | null;
  setRentalIncome: (data: RentalIncomeResponse) => void;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useRentalIncomeStore = create<RentalIncomeStore>((set) => ({
  rentalIncome: null,
  isLoading: false,

  setRentalIncome: (data) => set({ rentalIncome: data }),
  setLoading: (loading) => set({ isLoading: loading }),
}));
