import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Property from './components/Property';
import Overview from './components/Overview';
import Footer from './components/Footer';
import SignInSignUp from './components/SignInSignUp';
import { AuthProvider } from './helper/AuthContext';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Tenant from './components/Tenant';
import Messages from './components/Messages';
import Profile from './components/Profile';
import Subscription from './components/Subscription';
import NotFound from './components/NotFound';
import BuildingRequest from './components/BuildingRequest';
import MonthlyRevenue from './components/MonthlyRevenue';
import YearlyRevenue from './components/YearlyRevenue';
import AccessRequest from './components/AccessRequest';
import ProtectedRoute from './helper/ProtectedRoute';
import ChangePassword from './components/ChangePassword';
import VerifyEmail from './components/VerifyEmail';
import Box from '@mui/material/Box';

function App() {
  return (
    <AuthProvider>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Navbar />
        <Container sx={{ flexGrow: 1, py: 2 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/login" element={<SignInSignUp />} />

            {/* Protected Routes */}
            <Route
              path="/properties"
              element={<ProtectedRoute element={<Property />} />}
            />
            <Route
              path="/tenants"
              element={<ProtectedRoute element={<Tenant />} />}
            />
            <Route
              path="/subscription"
              element={<ProtectedRoute element={<Subscription />} />}
            />
            <Route
              path="/messages"
              element={<ProtectedRoute element={<Messages />} />}
            />
            <Route
              path="/building-requests"
              element={<ProtectedRoute element={<BuildingRequest />} />}
            />
            <Route
              path="/access-request"
              element={<ProtectedRoute element={<AccessRequest />} />}
            />
            <Route
              path="/monthly"
              element={<ProtectedRoute element={<MonthlyRevenue />} />}
            />
            <Route
              path="/yearly"
              element={<ProtectedRoute element={<YearlyRevenue />} />}
            />
            <Route
              path="/overview"
              element={<ProtectedRoute element={<Overview />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute element={<Profile />} />}
            />

            {/* Public Routes */}
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/verify/:token" element={<VerifyEmail />} />
            <Route path="/reset-password/:token" element={<ChangePassword />} />

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
        <Footer />
      </Box>
    </AuthProvider>
  );
}

export default App;
