import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff, Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApiResponse, Token } from '../utils/interfaces';
import { api } from '../utils/api';
import { validatePassword } from '../helper/validation';

interface ChangePasswordParams {
  Email: string;
  NewPassword: string;
}

const ChangePassword = () => {
  const { t } = useTranslation();
  const { token } = useParams<Token>();
  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});

  useEffect(() => {
    if (token) {
      api
        .verifyResetLink(token)
        .then((response: ApiResponse) => {
          if (response.success) {
            // @ts-ignore
            setUserEmail(response.success.userEmail);
          } else {
            setMessage({
              type: 'error',
              text: t('change_password.error.invalid_link'),
            });
          }
        })
        .catch(() => {
          setMessage({
            type: 'error',
            text: t('change_password.error.verification_failed'),
          });
        });
    } else {
      setMessage({
        type: 'error',
        text: t('change_password.error.invalid_token'),
      });
    }
  }, [token, t]);

  const handleTogglePassword = () => setShowPassword((prev) => !prev);

  const validateForm = () => {
    let valid = true;
    let newErrors: { password?: string; confirmPassword?: string } = {};

    const passwordErrorKey = validatePassword(password);
    if (passwordErrorKey) {
      newErrors.password = t(passwordErrorKey);
      valid = false;
    }
    if (confirmPassword !== password) {
      newErrors.confirmPassword = t('change_password.error.password_mismatch');
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        await api.changePassword({
          Email: userEmail,
          NewPassword: password,
        } as ChangePasswordParams);
        setMessage({
          type: 'success',
          text: t('change_password.success.update_success'),
        });
        setPassword('');
        setConfirmPassword('');
        setErrors({});
      } catch (error) {
        setMessage({
          type: 'error',
          text: t('change_password.error.update_failed'),
        });
        console.error('Error changing password:', error);
      }
    }
  };

  return (
    <Box sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 4,
          width: '100%',
          maxWidth: 400,
          textAlign: 'center',
        }}
      >
        <Lock sx={{ fontSize: 60, color: '#EC675D', mb: 2 }} />
        <Typography variant="h5" fontWeight="bold">
          {t('change_password.title')}
        </Typography>
        <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
          {t('change_password.subtitle')}
        </Typography>

        {message && (
          <Alert severity={message.type} sx={{ mb: 3 }}>
            {message.text}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            label={t('change_password.new_password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label={t('change_password.confirm_password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 3,
              py: 1.5,
              backgroundColor: '#EC675D',
              '&:hover': {
                backgroundColor: '#FF9B5D',
              },
            }}
          >
            {t('change_password.update_button')}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default ChangePassword;
