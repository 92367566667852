import React, { useState, useEffect, memo } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Tenant } from '../utils/interfaces';
import { formatDateForInput } from '../utils/dateUtils';
import { usePropertyStore } from '../stores/PropertyStore';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 2,
  p: 4,
  borderRadius: 1,
  maxHeight: '50vh',
  overflowY: 'auto',
};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  width: '100%',
};

interface TenantModalProps {
  open: boolean;
  onClose: () => void;
  tenant: Tenant | null;
  isEditMode: boolean;
  onSave: (tenant: Tenant | null) => void;
  validationErrors: { [key: string]: string };
}

const TenantModal: React.FC<TenantModalProps> = memo(
  ({ open, onClose, tenant, isEditMode, onSave, validationErrors }) => {
    const [editedTenant, setEditedTenant] = useState<Tenant | null>(null);
    const { properties } = usePropertyStore();
    const { t } = useTranslation();

    useEffect(() => {
      if (tenant) {
        setEditedTenant({ ...tenant });
      }
    }, [tenant]);

    if (!editedTenant) return null;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!editedTenant) return;

      const { name, value } = event.target;

      // Allow only numeric values for the Telephone field
      if (name === 'Telephone' && !/^\d*$/.test(value)) {
        return;
      }

      setEditedTenant((prev) => ({
        ...prev!,
        [name]: value,
      }));
    };

    const handlePropertyChange = (event: SelectChangeEvent<string>) => {
      if (!editedTenant) return;
      const selectedProperty = properties?.find(
        (property) => property.PropertyName === event.target.value
      );
      if (selectedProperty) {
        setEditedTenant((prev) => ({
          ...prev!,
          PropertyName: selectedProperty.PropertyName,
          PropertyID: selectedProperty.PropertyID,
        }));
      }
    };

    const handleSave = () => {
      if (onSave) {
        onSave(editedTenant);
      }
    };

    const isNewTenant = !tenant || !tenant.TenantID;

    return (
      <Modal
        open={open}
        onClose={onClose}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            {isEditMode
              ? isNewTenant
                ? t('Add New Tenant')
                : t('Edit Tenant')
              : t('View Tenant')}
          </Typography>

          <Box sx={gridStyle}>
            <TextField
              label={t('First Name')}
              name="FirstName"
              value={editedTenant.FirstName}
              onChange={handleChange}
              error={!!validationErrors.FirstName}
              helperText={validationErrors.FirstName}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
            />

            <TextField
              label={t('Last Name')}
              name="LastName"
              value={editedTenant.LastName}
              onChange={handleChange}
              error={!!validationErrors.LastName}
              helperText={validationErrors.LastName}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
            />

            <TextField
              label={t('Email')}
              name="Email"
              type="email"
              value={editedTenant.Email}
              onChange={handleChange}
              error={!!validationErrors.Email}
              helperText={validationErrors.Email}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
            />

            <TextField
              label={t('Telephone')}
              name="Telephone"
              type="tel"
              value={editedTenant.Telephone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
              slotProps={{
                input: {
                  inputMode: 'numeric',
                },
              }}
            />

            <TextField
              label={t('Unit')}
              name="Unit"
              value={editedTenant.Unit}
              onChange={handleChange}
              error={!!validationErrors.Unit}
              helperText={validationErrors.Unit}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
            />

            <TextField
              label={t('Complex')}
              name="Complex"
              value={editedTenant.Complex}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled={!isEditMode}
            />

            <TextField
              label={t('Start Date')}
              name="StartDate"
              type="date"
              value={formatDateForInput(editedTenant.StartDate)}
              onChange={handleChange}
              error={!!validationErrors.StartDate}
              helperText={validationErrors.StartDate}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              disabled={!isEditMode}
            />

            <TextField
              label={t('End Date')}
              name="EndDate"
              type="date"
              value={formatDateForInput(editedTenant.EndDate)}
              onChange={handleChange}
              error={!!validationErrors.EndDate}
              helperText={validationErrors.EndDate}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              disabled={!isEditMode}
            />

            {isNewTenant && (
              <FormControl
                fullWidth
                margin="normal"
                disabled={!isEditMode}
                error={!!validationErrors.PropertyName}
              >
                <InputLabel>{t('Property')}</InputLabel>
                <Select
                  name="PropertyName"
                  value={editedTenant.PropertyName || ''}
                  onChange={handlePropertyChange}
                >
                  {properties?.map((property) => (
                    <MenuItem
                      key={property.PropertyID}
                      value={property.PropertyName}
                    >
                      {property.PropertyName}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.PropertyName && (
                  <FormHelperText>
                    {validationErrors.PropertyName}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Box>

          <Box
            sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              sx={{
                '&:hover': {
                  backgroundColor: '#B22222',
                  color: 'white',
                },
              }}
              onClick={onClose}
            >
              {isEditMode ? t('Cancel') : t('Close')}
            </Button>
            {isEditMode && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: '#EC675D',
                  '&:hover': {
                    backgroundColor: '#FF9B5D',
                  },
                }}
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                {isNewTenant ? t('Save') : t('Update')}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }
);

export default TenantModal;
