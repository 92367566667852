import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useFetchMessages } from '../hooks/message.hooks';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { useMessageStore } from '../stores/MessageStore';
import { Message } from '../utils/interfaces';
import { formatDate } from '../utils/dateUtils';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { api } from '../utils/api';
import { deleteKeyStore } from '../utils/indexedDB';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../helper/LoadingSpinner';

const MessageView = () => {
  const { t } = useTranslation();
  const {
    isError: isMessageError,
    error: messageError,
    refetch,
  } = useFetchMessages();
  const { messages, isMessageLoading } = useMessageStore();

  useEffect(() => {
    if (isMessageLoading) {
      <LoadingSpinner />;
      return;
    }
  }, [isMessageLoading]);

  useEffect(() => {
    if (isMessageError) {
      ErrorHandler.handleError(messageError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageError, messageError]);

  const [filteredMessages, setFilteredMessages] = useState<Message[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);
  const errorMessages = new ErrorMessages();

  useEffect(() => {
    if (messages) {
      setFilteredMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (isMessageError) {
      ErrorHandler.handleError(messageError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageError, messageError]);

  const handleSelectMessage = (messageId: number) => {
    setSelectedMessages((prevSelectedMessages) =>
      prevSelectedMessages.includes(messageId)
        ? prevSelectedMessages.filter((id) => id !== messageId)
        : [...prevSelectedMessages, messageId]
    );
  };

  const handleViewMessage = (message: Message) => {
    setSelectedMessage(message);
    setOpenViewModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedMessages.length === 0) {
      toast.error(t('no_messages_selected_for_deletion'));
      return;
    }

    console.log('Deleting messages:', selectedMessages);

    // Call delete API for each selected message
    Promise.all(
      selectedMessages.map((messageID) => api.deleteMessage(messageID))
    )
      .then(() => {
        toast.success(t('message_deleted_success'));

        setFilteredMessages((prevMessages) =>
          prevMessages.filter(
            (msg) => !selectedMessages.includes(msg.MessageID)
          )
        );

        return deleteKeyStore('messages');
      })
      .then(() => {
        handleRefetch();
      })
      .catch((error) => {
        toast.error(t(error.message));
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setSelectedMessages([]);
      });
  };

  const handleRefetch = () => {
    refetch()
      .then(() => {
        console.log('Data successfully refetched');
      })
      .catch((error) => {
        console.error('Error refetching tenants:', error);
      });
  };

  const handleDeleteSelectedMessages = () => {
    if (selectedMessages.length === 0) {
      toast.error(t('select_at_least_one_message'));
      return;
    }
    setOpenDeleteModal(true);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('messages')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('message_count', { count: messages?.length })}
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ mt: 4, maxHeight: '400px', overflow: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedMessages.length === filteredMessages.length}
                  onChange={(e) =>
                    setSelectedMessages(
                      e.target.checked
                        ? filteredMessages.map((msg) => msg.MessageID)
                        : []
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <strong>{t('tenant')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('subject')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('date_sent')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('actions')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMessages.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1" color="textSecondary">
                    {t('no_data_available')}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredMessages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((msg, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedMessages.includes(msg.MessageID)}
                        onChange={() => handleSelectMessage(msg.MessageID)}
                      />
                    </TableCell>
                    <TableCell>
                      {`${msg.FirstName || ''} ${msg.LastName || ''}`.trim()}
                    </TableCell>
                    <TableCell>{msg.Subject}</TableCell>
                    <TableCell>
                      {msg.SentDate ? formatDate(msg.SentDate) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {/* View Icon */}
                      <Tooltip title={t('view')} arrow>
                        <IconButton
                          color="primary"
                          sx={{
                            '&:hover': {
                              backgroundColor: '#FF9B5D',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: 'white',
                            },
                          }}
                          onClick={() => handleViewMessage(msg)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {/* Delete Icon */}
                      <Tooltip title={t('delete')} arrow>
                        <IconButton
                          color="error"
                          sx={{
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                            '& svg': {
                              color: 'black',
                            },
                            '&:hover svg': {
                              color: 'red',
                            },
                          }}
                          onClick={() => handleDeleteSelectedMessages()}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredMessages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* View Message Modal */}
      <Dialog open={openViewModal} onClose={() => setOpenViewModal(false)}>
        <DialogTitle>{t('message_details')}</DialogTitle>
        <DialogContent>
          {selectedMessage && (
            <>
              <Typography>
                <strong>{t('tenant_label')}:</strong>{' '}
                {`${selectedMessage.FirstName || ''} ${selectedMessage.LastName || ''}`}
              </Typography>
              <Typography>
                <strong>{t('subject_label')}:</strong> {selectedMessage.Subject}
              </Typography>
              <Typography>
                <strong>{t('date_sent_label')}:</strong>{' '}
                {selectedMessage.SentDate
                  ? new Date(selectedMessage.SentDate)
                      .toLocaleString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                      })
                      .replace(',', ' -')
                  : 'N/A'}
              </Typography>
              {selectedMessage.Description && (
                <>
                  <Typography sx={{ mt: 2 }}>
                    <strong>{t('description_label')}:</strong>
                  </Typography>
                  <Typography>{selectedMessage.Description}</Typography>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenViewModal(false)} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>{t('delete_message')}</DialogTitle>
        <DialogContent>
          <Typography>{t('delete_confirmation')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
            {t('no')}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageView;
