import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import Insights from '@mui/icons-material/Insights';
import SupportAgent from '@mui/icons-material/SupportAgent';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Define the types for the features and members
interface Feature {
  title: string;
  description: string;
}

interface Member {
  name: string;
  role: string;
  image: string;
}

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: 'center', mt: 4, px: 2 }}>
      {/* About Us - Hero Section */}
      <Box component="section">
        <Typography variant="h2" fontWeight="bold">
          {t('about_us.hero_section.title')}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 4 }}>
          {t('about_us.hero_section.subtitle')}
        </Typography>
      </Box>

      {/* Our Mission & Vision */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('about_us.mission_vision.mission.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 2, mb: 4 }}
          dangerouslySetInnerHTML={{
            __html: t('about_us.mission_vision.mission.description'),
          }}
        />

        <Typography variant="h4" fontWeight="bold">
          {t('about_us.mission_vision.vision.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 2, mb: 4 }}
          dangerouslySetInnerHTML={{
            __html: t('about_us.mission_vision.vision.description'),
          }}
        />
      </Box>

      {/* Why Choose Us Section */}
      <Box component="section" sx={{ mt: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('about_us.why_choose_us.title')}
        </Typography>
        <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
          {/* Cast the result of `t()` to the correct type */}
          {(
            t('about_us.why_choose_us.features', {
              returnObjects: true,
            }) as Feature[]
          ).map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <Paper
                sx={{
                  p: 3,
                  textAlign: 'center',
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                  minHeight: 250,
                }}
              >
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    backgroundColor: 'warning.dark',
                    margin: 'auto',
                  }}
                >
                  {index === 0 && (
                    <VerifiedUser sx={{ fontSize: 60, color: 'white' }} />
                  )}
                  {index === 1 && (
                    <Insights sx={{ fontSize: 60, color: 'white' }} />
                  )}
                  {index === 2 && (
                    <SupportAgent sx={{ fontSize: 60, color: 'white' }} />
                  )}
                  {index === 3 && (
                    <EmojiPeople sx={{ fontSize: 60, color: 'white' }} />
                  )}
                </Avatar>
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {item.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* How It Works Section */}
      <Box component="section" sx={{ mt: 6, textAlign: 'left' }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          {t('about_us.how_it_works.title')}
        </Typography>
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          {(
            t('about_us.how_it_works.steps', {
              returnObjects: true,
            }) as string[]
          ).map((step, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{ mt: 1 }}
              dangerouslySetInnerHTML={{ __html: step }}
            />
          ))}
        </Box>
      </Box>

      {/* Meet The Team Section */}
      <Box component="section" sx={{ mt: 8 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('about_us.meet_the_team.title')}
        </Typography>
        <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
          {/* Cast the result of `t()` to the correct type */}
          {(
            t('about_us.meet_the_team.members', {
              returnObjects: true,
            }) as Member[]
          ).map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                  minHeight: 100,
                }}
              >
                {/* <img
                  src={member.image}
                  alt={member.name}
                  style={{ width: '100%', borderRadius: '4px 4px 0 0' }}
                /> */}
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {member.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {member.role}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box component="section" sx={{ mt: 8 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('about_us.testimonials.title')}
        </Typography>
        <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
          {/* Cast the result of `t()` to the correct type */}
          {(
            t('about_us.testimonials.quotes', { returnObjects: true }) as {
              quote: string;
              author: string;
            }[]
          ).map((testimonial, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  p: 3,
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                  minHeight: 100,
                }}
              >
                <Typography variant="body1" fontStyle="italic">
                  “{testimonial.quote}”
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{ mt: 2 }}
                >
                  - {testimonial.author}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Final Call to Action */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          {t('about_us.call_to_action.title')}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          {t('about_us.call_to_action.description')}
        </Typography>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: '#EC675D',
              '&:hover': {
                backgroundColor: '#FF9B5D',
              },
            }}
            size="large"
          >
            {t('about_us.call_to_action.button')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default AboutUs;
