import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 1,
        background: 'linear-gradient(90deg,#792954 0%, #EC675D 100%)',
        color: '#fff',
        mt: 8,
      }}
    >
      <Typography variant="body2">
        &copy; {t('footer.copyright', { year: currentYear })}
      </Typography>

      {/* Links Section */}
      <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 1 }}>
        <Link href="/privacy" target="_blank" color="inherit" underline="hover">
          {t('footer.privacyPolicy')}
        </Link>
        <Link href="/terms" target="_blank" color="inherit" underline="hover">
          {t('footer.termsConditions')}
        </Link>
      </Stack>

      {/* Contact Info Section */}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Typography variant="body2">
          <Link
            href="mailto:support@leasesphere.com"
            color="inherit"
            underline="hover"
          >
            {t('footer.contactEmail')}
          </Link>
        </Typography>
        <Typography variant="body2">
          <Link href="tel:+2347030603370" color="inherit" underline="hover">
            {t('footer.contactPhone')}
          </Link>
        </Typography>
        <Typography variant="body2">{t('footer.location')}</Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
