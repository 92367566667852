import { api } from '../utils/api';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Subscription } from '../utils/interfaces';

export const useSubscriptions = () => {
  return useQuery<Subscription[]>({
    queryKey: ['subscriptions'],
    queryFn: api.getSubscription,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });
};
