export interface Country {
  name: string;
  currency: string;
  code?: string;
}

const currencySymbols: { [key: string]: string } = {
  NGN: '₦',
  ETB: 'Br',
  EGP: '£',
  CDF: 'FC',
  ZAR: 'R',
  TZS: 'TSh',
  KES: 'KSh',
  UGX: 'USh',
  DZD: 'د.ج',
  SDG: 'ج.س.',
  MAD: 'د.م.',
  AOA: 'Kz',
  MZN: 'MT',
  GHS: '₵',
  MGA: 'Ar',
  XAF: 'FCFA',
  XOF: 'CFA',
  MWK: 'MK',
  ZMW: 'ZK',
  SOS: 'Sh',
  ZWL: '$',
  GNF: 'FG',
  RWF: 'FRw',
  BIF: 'FBu',
  TND: 'د.ت',
  SSP: '£',
  SLL: 'Le',
  LYD: 'ل.د',
  LRD: 'L$',
  MRU: 'UM',
  ERN: 'Nfk',
  NAD: 'N$',
  GMD: 'D',
  BWP: 'P',
  LSL: 'L',
  MUR: '₨',
  SZL: 'E',
  DJF: 'Fdj',
  KMF: 'CF',
  CVE: '$',
  STN: 'Db',
  SCR: '₨',
};

export const countries: Country[] = [
  { name: 'Nigeria', currency: currencySymbols.NGN },
  { name: 'Ethiopia', currency: currencySymbols.ETB },
  { name: 'Egypt', currency: currencySymbols.EGP },
  { name: 'Democratic Republic of the Congo', currency: currencySymbols.CDF },
  { name: 'South Africa', currency: currencySymbols.ZAR },
  { name: 'Tanzania', currency: currencySymbols.TZS },
  { name: 'Kenya', currency: currencySymbols.KES },
  { name: 'Uganda', currency: currencySymbols.UGX },
  { name: 'Algeria', currency: currencySymbols.DZD },
  { name: 'Sudan', currency: currencySymbols.SDG },
  { name: 'Morocco', currency: currencySymbols.MAD },
  { name: 'Angola', currency: currencySymbols.AOA },
  { name: 'Mozambique', currency: currencySymbols.MZN },
  { name: 'Ghana', currency: currencySymbols.GHS },
  { name: 'Madagascar', currency: currencySymbols.MGA },
  { name: 'Cameroon', currency: currencySymbols.XAF },
  { name: "Côte d'Ivoire", currency: currencySymbols.XOF },
  { name: 'Niger', currency: currencySymbols.XOF },
  { name: 'Burkina Faso', currency: currencySymbols.XOF },
  { name: 'Mali', currency: currencySymbols.XOF },
  { name: 'Malawi', currency: currencySymbols.MWK },
  { name: 'Zambia', currency: currencySymbols.ZMW },
  { name: 'Senegal', currency: currencySymbols.XOF },
  { name: 'Chad', currency: currencySymbols.XAF },
  { name: 'Somalia', currency: currencySymbols.SOS },
  { name: 'Zimbabwe', currency: currencySymbols.ZWL },
  { name: 'Guinea', currency: currencySymbols.GNF },
  { name: 'Rwanda', currency: currencySymbols.RWF },
  { name: 'Benin', currency: currencySymbols.XOF },
  { name: 'Burundi', currency: currencySymbols.BIF },
  { name: 'Tunisia', currency: currencySymbols.TND },
  { name: 'South Sudan', currency: currencySymbols.SSP },
  { name: 'Togo', currency: currencySymbols.XOF },
  { name: 'Sierra Leone', currency: currencySymbols.SLL },
  { name: 'Libya', currency: currencySymbols.LYD },
  { name: 'Congo', currency: currencySymbols.XAF },
  { name: 'Liberia', currency: currencySymbols.LRD },
  { name: 'Central African Republic', currency: currencySymbols.XAF },
  { name: 'Mauritania', currency: currencySymbols.MRU },
  { name: 'Eritrea', currency: currencySymbols.ERN },
  { name: 'Namibia', currency: currencySymbols.NAD },
  { name: 'Gambia', currency: currencySymbols.GMD },
  { name: 'Botswana', currency: currencySymbols.BWP },
  { name: 'Gabon', currency: currencySymbols.XAF },
  { name: 'Lesotho', currency: currencySymbols.LSL },
  { name: 'Guinea-Bissau', currency: currencySymbols.XOF },
  { name: 'Equatorial Guinea', currency: currencySymbols.XAF },
  { name: 'Mauritius', currency: currencySymbols.MUR },
  { name: 'Eswatini', currency: currencySymbols.SZL },
  { name: 'Djibouti', currency: currencySymbols.DJF },
  { name: 'Comoros', currency: currencySymbols.KMF },
  { name: 'Cabo Verde', currency: currencySymbols.CVE },
  { name: 'Sao Tome and Principe', currency: currencySymbols.STN },
  { name: 'Seychelles', currency: currencySymbols.SCR },
];
