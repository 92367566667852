import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { toast } from 'react-toastify';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { Tenant } from '../utils/interfaces';
import { useFetchTenants } from '../hooks/tenant.hooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../helper/AuthContext';
import { SubscriptionType, UserRoleType } from '../types/types';
import { useRentalIncomeStore } from '../stores/RentalIncomeStore';
import LoadingSpinner from '../helper/LoadingSpinner';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const DepartmentChart = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const { subscriptionType, userRoleType } = useAuth();
  const { rentalIncome, isLoading: isRentalIncomeLoading } =
    useRentalIncomeStore();

  const {
    data: tenantsData,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
  } = useFetchTenants();

  useEffect(() => {
    if (isRentalIncomeLoading || isTenantsLoading) {
      return;
    }

    if (isTenantsError) {
      ErrorHandler.handleError(
        'Error fetching tenants data',
        errorMessages,
        toast
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rentalIncome,
    isRentalIncomeLoading,
    tenantsData,
    isTenantsLoading,
    isTenantsError,
  ]);

  const getExpiredTenants = (tenants: Tenant[]): Tenant[] => {
    const currentDate = new Date();
    return tenants.filter((tenant) => {
      const endDate = new Date(tenant.EndDate);
      return endDate < currentDate;
    });
  };

  const getCurrentTenants = (tenants: Tenant[]): Tenant[] => {
    const currentDate = new Date();
    return tenants.filter((tenant) => {
      const startDate = new Date(tenant.StartDate);
      const endDate = new Date(tenant.EndDate);
      return startDate <= currentDate && endDate >= currentDate;
    });
  };

  if (isRentalIncomeLoading || isTenantsLoading) {
    return <LoadingSpinner />;
  }

  // Ensure tenantsData is available and not empty
  if (!tenantsData || tenantsData.length === 0) {
    return (
      <Box sx={{ mt: 8 }}>
        <p>{t('no_tenants_data_available')}</p>
      </Box>
    );
  }

  // Get expired tenants and current tenants
  const expiredTenants = getExpiredTenants(tenantsData);
  const currentTenants = getCurrentTenants(tenantsData);

  // Calculate total collected rent and total outstanding rent
  const totalCollectedRent = currentTenants.reduce((sum, tenant) => {
    const tenantInRentalIncome = rentalIncome?.Tenants.find(
      (rentalTenant) => rentalTenant.TenantID === tenant.TenantID
    );
    return sum + (tenantInRentalIncome?.RoomPrice ?? 0);
  }, 0);

  const totalOutstandingRent = expiredTenants.reduce((sum, tenant) => {
    const tenantInRentalIncome = rentalIncome?.Tenants.find(
      (rentalTenant) => rentalTenant.TenantID === tenant.TenantID
    );
    return sum + (tenantInRentalIncome?.RoomPrice ?? 0);
  }, 0);

  const totalRent =
    rentalIncome?.Tenants.reduce(
      (sum, tenant) => sum + (tenant.RoomPrice ?? 0),
      0
    ) ?? 1; // Default to 1 to avoid division by 0

  const totalCollectedPercent = Math.round(
    (totalCollectedRent / totalRent) * 100
  );
  const totalOutstandingPercent = Math.round(
    (totalOutstandingRent / totalRent) * 100
  );

  const data = {
    labels: [
      t('total_expired_rooms'),
      t('total_rented_rooms'),
      ...(userRoleType === UserRoleType.Landlord &&
      subscriptionType === SubscriptionType.Premium
        ? [
            t('total_collected_rent_percent'),
            t('total_outstanding_rent_percent'),
          ]
        : []),
    ],
    datasets: [
      {
        data: [
          expiredTenants.length,
          currentTenants.length,
          ...(userRoleType === UserRoleType.Landlord &&
          subscriptionType === SubscriptionType.Premium
            ? [totalCollectedPercent, totalOutstandingPercent]
            : []),
        ],
        backgroundColor: ['#f44336', '#4caf50'],
        ...(userRoleType === UserRoleType.Landlord &&
        subscriptionType === SubscriptionType.Premium
          ? { backgroundColor: ['#f44336', '#4caf50', '#2196f3', '#ff9800'] }
          : {}),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Paper
        sx={{
          p: 2,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h4" fontWeight="bold" color="black" sx={{ mb: 4 }}>
          {t('property_overview')}
        </Typography>

        <Box sx={{ width: 400, height: 400 }}>
          <Pie data={data} options={options} />
        </Box>
      </Paper>
    </Box>
  );
};

export default DepartmentChart;
