import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';

const TermsConditions = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('terms_and_conditions')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>1. {t('scope_of_services_title')}</strong>{' '}
          {t('scope_of_services')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. {t('rental_agreement_title')}</strong>{' '}
          {t('rental_agreement')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>3. {t('property_use_title')}</strong> {t('property_use')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>4. {t('maintenance_repairs_title')}</strong>{' '}
          {t('maintenance_repairs')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>5. {t('rent_payments_title')}</strong> {t('rent_payments')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>6. {t('termination_title')}</strong> {t('termination')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>7. {t('privacy_security_title')}</strong>{' '}
          {t('privacy_security')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>8. {t('liability_title')}</strong> {t('liability')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>9. {t('modifications_to_terms_title')}</strong>{' '}
          {t('modifications_to_terms')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>10. {t('governing_law_title')}</strong> {t('governing_law')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>11. {t('refund_policy_title')}</strong> {t('refund_policy')}
        </Typography>

        <Typography variant="body1" paragraph>
          • <strong>{t('three_month_plan_title')}</strong>{' '}
          {t('three_month_plan')}
          <br />• <strong>{t('six_month_plan_title')}</strong>{' '}
          {t('six_month_plan')}
          <br />• <strong>{t('one_year_plan_title')}</strong>{' '}
          {t('one_year_plan')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('cancellation')}
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>12. {t('delivery_policy_title')}</strong>{' '}
          {t('delivery_policy')}
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsConditions;
