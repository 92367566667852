import React, { useState, FormEvent, ChangeEvent } from 'react';
import Link from '@mui/material/Link';
import { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { validateEmail, validatePassword } from '../helper/validation';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { User, ApiResponse } from '../utils/interfaces';
import { setEncryptedAccessToken } from '../utils/TokenStorage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../helper/AuthContext';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignInSignUp = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [referralEmail, setReferralEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const errorMessages = new ErrorMessages();
  const { login } = useAuth();

  const handleLoginSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Honeypot check (if applicable)
    const honeypot = (document.getElementById('honeypot') as HTMLInputElement)
      ?.value;
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    // Validate email
    if (!validateEmail(email)) {
      setMessage(t('email_not_valid'));
      return;
    }

    setLoading(true); // Start loading

    const loginData: User = {
      Email: email,
      Password: password,
      RememberMe: rememberMe,
    };

    api
      .login(loginData)
      .then((response) => {
        // Extract access token
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          login(); // Update auth state
          navigate('/overview', { replace: true });
        }
      })
      .catch((error: ApiResponse) => {
        const errorMessage =
          ErrorHandler.handleError(error, errorMessages, toast, true) ||
          error.message;
        setMessage(errorMessage);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const handleRegisterSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission

    // Honeypot check (if you have a hidden input for bot detection)
    const honeypot = (document.getElementById('honeypot') as HTMLInputElement)
      ?.value;
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    // Validate email
    if (!validateEmail(email)) {
      setMessage(t('email_not_valid'));
      return;
    }

    // Validate password
    const passwordErrorKey = validatePassword(password);
    if (passwordErrorKey) {
      setMessage(t(passwordErrorKey));
      return;
    }

    // Confirm passwords match
    if (password !== confirmPassword) {
      setMessage(t('passwords_do_not_match'));
      return;
    }

    // Validate referral email (if provided)
    if (referralEmail.trim() !== '' && !validateEmail(referralEmail)) {
      setMessage(t('email_not_valid'));
      return;
    }

    // Validate user role
    if (!role) {
      setMessage(t('please_select_role'));
      return;
    }

    setLoading(true); // Start loading

    const languageCode = i18n.language.split('-')[0];
    const userName = email.split('@')[0];
    const formattedUserName =
      userName.charAt(0).toUpperCase() + userName.slice(1).toLowerCase();

    const registerData: User = {
      Username: formattedUserName,
      Password: password,
      Email: email,
      UserRoleType: role,
      DefaultLanguage: languageCode,
      ReferralEmail: referralEmail,
    };

    api
      .register(registerData)
      .then((response) => {
        // Extract access token
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          login(); // Update auth state
          navigate('/overview', { replace: true });
        }

        setMessage(t('registration_successful'));
      })
      .catch((error: ApiResponse) => {
        const errorMessage =
          ErrorHandler.handleError(error, errorMessages, toast, true) ||
          error.message;
        setMessage(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleForgotPasswordSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const honeypot = (document.getElementById('honeypot') as HTMLInputElement)
      ?.value;
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(forgotPasswordEmail)) {
      setMessage(t('invalid_email'));
      return;
    }

    api
      .resetPasswordEmail(forgotPasswordEmail)
      .then(() => {
        setMessage(t('password_recovery', { email: forgotPasswordEmail }));
      })
      .catch((error: ApiResponse) => {
        const errorMessage =
          ErrorHandler.handleError(error, errorMessages, toast, true) ||
          error.message;
        setMessage(errorMessage);
      });
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    setRole(e.target.value);
  };

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleForgotPasswordClick = () => {
    setIsForgotPassword(true);
  };

  return (
    <Box sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h3" fontWeight="bold">
        {isForgotPassword
          ? t('forgot_password')
          : isLogin
            ? t('login')
            : t('register')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {isForgotPassword
          ? t('enter_email_reset')
          : isLogin
            ? t('welcome_back')
            : t('create_account')}
      </Typography>

      <Box
        component="form"
        onSubmit={
          isForgotPassword
            ? handleForgotPasswordSubmit
            : isLogin
              ? handleLoginSubmit
              : handleRegisterSubmit
        }
        sx={{ maxWidth: 600, mx: 'auto' }}
      >
        <Grid container spacing={3}>
          {isForgotPassword ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('email_address')}
                type="email"
                variant="outlined"
                required
                value={forgotPasswordEmail}
                onChange={handleInputChange(setForgotPasswordEmail)}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('email_address')}
                  type="email"
                  variant="outlined"
                  required
                  value={email}
                  onChange={handleInputChange(setEmail)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('password')}
                  type={passwordVisible ? 'text' : 'password'}
                  variant="outlined"
                  required
                  value={password}
                  onChange={handleInputChange(setPassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {!isLogin && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('confirm_password')}
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    variant="outlined"
                    required
                    value={confirmPassword}
                    onChange={handleInputChange(setConfirmPassword)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          >
                            {confirmPasswordVisible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* Role Dropdown */}
              {!isLogin && (
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>{t('role')}</InputLabel>
                    <Select
                      value={role}
                      onChange={handleRoleChange}
                      label={t('role')}
                    >
                      <MenuItem value="Landlord">{t('landlord')}</MenuItem>
                      <MenuItem value="Caretaker">{t('caretaker')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Referral Email - Optional */}
              {!isLogin && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('referral_email')}
                    type="email"
                    variant="outlined"
                    value={referralEmail}
                    onChange={handleInputChange(setReferralEmail)}
                    helperText={t('referral_helper_text')}
                  />
                </Grid>
              )}

              {/* Remember Me checkbox */}
              {isLogin && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        color="primary"
                      />
                    }
                    label={t('remember_me')}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#EC675D',
                '&:hover': {
                  backgroundColor: '#FF9B5D',
                },
              }}
              color="success"
              size="large"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : isForgotPassword ? (
                t('submit')
              ) : isLogin ? (
                t('login')
              ) : (
                t('register')
              )}
            </Button>
          </Grid>
        </Grid>
        {message && (
          <Typography
            sx={{ mt: 2 }}
            color={message.includes('success') ? 'green' : 'red'}
          >
            {message}
          </Typography>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        {isForgotPassword ? (
          <Typography variant="body2">
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsForgotPassword(false);
              }}
            >
              {t('back_to_login')}
            </Link>
          </Typography>
        ) : (
          <Typography variant="body2">
            {isLogin ? t('dont_have_account') : t('already_have_account')}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsLogin(!isLogin);
                setMessage('');
              }}
            >
              {isLogin ? t('register') : t('login')}
            </Link>
            {isLogin && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                <Link href="#" onClick={handleForgotPasswordClick}>
                  {t('forgot_password_link')}
                </Link>
              </Typography>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SignInSignUp;
