import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" color="error" fontWeight="bold">
        404
      </Typography>
      <Typography variant="h5" sx={{ my: 2 }}>
        {t('page_not_found')}
      </Typography>
      <Button variant="contained" component={Link} to="/" sx={{ mt: 2 }}>
        {t('go_home')}
      </Button>
    </Box>
  );
};

export default NotFound;
