import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Property } from '../utils/interfaces';
import { usePropertyStore } from './../stores/PropertyStore';

// Fetch properties from API
const fetchPropertiesFromApi = async (): Promise<Property[]> => {
  const response = await api.getPropertiesByUser();
  const sortedProperties = response.sort((a: Property, b: Property) =>
    a.PropertyName.localeCompare(b.PropertyName)
  ); // Sort alphabetically by 'PropertyName'

  // Encrypt and save the properties data to IndexedDB
  const encryptedData = encryptData(JSON.stringify(sortedProperties));
  await saveData('properties', encryptedData);

  return sortedProperties;
};

// Load properties from IndexedDB
const loadPropertiesFromIndexedDB = async (): Promise<Property[] | null> => {
  const savedProperties = await getData('properties');

  if (savedProperties && savedProperties.value.length > 0) {
    const decryptedData = decryptData(savedProperties.value);
    return JSON.parse(decryptedData);
  } else {
    return null;
  }
};

// React Query hook to fetch properties
export const useFetchProperties = () => {
  const { setProperties, setLoading } = usePropertyStore();

  const fetchProperties = async (propertiesQuery: any): Promise<Property[]> => {
    const propertiesFromDB = await loadPropertiesFromIndexedDB();

    if (propertiesFromDB && !propertiesQuery.isStale) {
      setProperties(propertiesFromDB);
      return propertiesFromDB;
    }

    const propertiesFromApi = await fetchPropertiesFromApi();
    setProperties(propertiesFromApi);
    return propertiesFromApi;
  };

  const propertiesQuery = useQuery<Property[]>({
    queryKey: ['properties'],
    queryFn: async (queryFnContext) => {
      setLoading(true);

      try {
        return await fetchProperties(queryFnContext);
      } finally {
        setLoading(false);
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });

  return propertiesQuery;
};
